import React from 'react';
import { Alert, Snackbar, Button } from '@mui/material';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import { useOnlineStatus } from '../utils/hooks';

const OfflineNotice = () => {
  const isOnline = useOnlineStatus();
  const [showOfflineMessage, setShowOfflineMessage] = React.useState(false);
  
  React.useEffect(() => {
    if (!isOnline) {
      setShowOfflineMessage(true);
    }
  }, [isOnline]);
  
  const handleClose = () => {
    setShowOfflineMessage(false);
  };
  
  return (
    <Snackbar
      open={!isOnline && showOfflineMessage}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert
        severity="warning"
        icon={<WifiOffIcon />}
        action={
          <Button color="inherit" size="small" onClick={handleClose}>
            Dismiss
          </Button>
        }
        sx={{ 
          width: '100%',
          bgcolor: 'rgba(30, 30, 30, 0.95)',
          color: 'white',
          border: '1px solid #f57c00'
        }}
      >
        You're offline. Some features may be limited.
      </Alert>
    </Snackbar>
  );
};

export default OfflineNotice; 