import axios from 'axios';
import { logEvent } from './analytics';

// Get API key from environment or use fallback
const RAPIDAPI_KEY = typeof window !== 'undefined' && window.ENV_RAPIDAPI_KEY 
  ? window.ENV_RAPIDAPI_KEY 
  : '6d56d45340msh577d75cac272d85p134e1bjsn4d25e3bb73e8';

// Base configuration for YouTube138 RapidAPI
const rapidApiConfig = {
  baseURL: 'https://youtube138.p.rapidapi.com',
  headers: {
    'x-rapidapi-key': RAPIDAPI_KEY,
    'x-rapidapi-host': 'youtube138.p.rapidapi.com'
  }
};

// Create axios instance with base configuration
const rapidApiClient = axios.create(rapidApiConfig);

// Add response interceptor for error handling
rapidApiClient.interceptors.response.use(
  response => response,
  error => {
    console.error('RapidAPI Error:', error);
    logEvent('rapidapi_error', {
      endpoint: error.config?.url || 'unknown',
      status: error.response?.status || 'unknown',
      message: error.message || 'unknown'
    });
    return Promise.reject(error);
  }
);

/**
 * Fetch detailed information about a video
 * @param {string} videoId - YouTube video ID
 * @param {string} language - Language code (default: 'en')
 * @returns {Promise<Object>} - Video details
 */
export const getVideoDetails = async (videoId, language = 'en') => {
  try {
    const response = await rapidApiClient.get('/v2/video-details', {
      params: {
        video_id: videoId,
        hl: language
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching video details:', error);
    throw error;
  }
};

/**
 * Fetch comments for a video
 * @param {string} videoId - YouTube video ID
 * @param {string} language - Language code (default: 'en')
 * @param {string} region - Region code (default: 'US')
 * @returns {Promise<Object>} - Video comments
 */
export const getVideoComments = async (videoId, language = 'en', region = 'US') => {
  try {
    const response = await rapidApiClient.get('/video/comments/', {
      params: {
        id: videoId,
        hl: language,
        gl: region
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching video comments:', error);
    throw error;
  }
};

/**
 * Fetch related videos for a video
 * @param {string} videoId - YouTube video ID
 * @param {string} language - Language code (default: 'en')
 * @param {string} region - Region code (default: 'US')
 * @returns {Promise<Object>} - Related videos
 */
export const getRelatedVideos = async (videoId, language = 'en', region = 'US') => {
  try {
    const response = await rapidApiClient.get('/video/related-contents/', {
      params: {
        id: videoId,
        hl: language,
        gl: region
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching related videos:', error);
    throw error;
  }
};

/**
 * Fetch streaming data for a video
 * @param {string} videoId - YouTube video ID
 * @returns {Promise<Object>} - Streaming data
 */
export const getStreamingData = async (videoId) => {
  try {
    const response = await rapidApiClient.get('/video/streaming-data/', {
      params: {
        id: videoId
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching streaming data:', error);
    throw error;
  }
};

/**
 * Transform RapidAPI video data to match the format expected by the app
 * @param {Object} rapidApiVideo - Video data from RapidAPI
 * @returns {Object} - Transformed video object
 */
export const transformRapidApiVideo = (rapidApiVideo) => {
  if (!rapidApiVideo) return null;
  
  return {
    id: {
      videoId: rapidApiVideo.videoId
    },
    snippet: {
      publishedAt: rapidApiVideo.publishedDate,
      channelId: rapidApiVideo.author?.channelId,
      title: rapidApiVideo.title,
      description: rapidApiVideo.description,
      thumbnails: {
        high: {
          url: rapidApiVideo.thumbnails?.[0]?.url || ''
        },
        medium: {
          url: rapidApiVideo.thumbnails?.[1]?.url || rapidApiVideo.thumbnails?.[0]?.url || ''
        },
        default: {
          url: rapidApiVideo.thumbnails?.[2]?.url || rapidApiVideo.thumbnails?.[0]?.url || ''
        }
      },
      channelTitle: rapidApiVideo.author?.title || '',
      tags: rapidApiVideo.keywords || []
    },
    statistics: {
      viewCount: rapidApiVideo.stats?.views || 0,
      likeCount: rapidApiVideo.stats?.likes || 0,
      commentCount: rapidApiVideo.stats?.comments || 0
    },
    contentDetails: {
      duration: rapidApiVideo.lengthSeconds ? `PT${Math.floor(rapidApiVideo.lengthSeconds / 60)}M${rapidApiVideo.lengthSeconds % 60}S` : 'PT0M0S'
    }
  };
};

/**
 * Transform RapidAPI related videos to match the format expected by the app
 * @param {Object} relatedContents - Related contents data from RapidAPI
 * @returns {Array} - Array of transformed video objects
 */
export const transformRelatedVideos = (relatedContents) => {
  if (!relatedContents || !relatedContents.contents) return [];
  
  return relatedContents.contents
    .filter(item => item.type === 'video')
    .map(item => transformRapidApiVideo(item.video));
};

/**
 * Fetch and transform video details and related videos in one call
 * @param {string} videoId - YouTube video ID
 * @param {string} language - Language code (default: 'en')
 * @param {string} region - Region code (default: 'US')
 * @returns {Promise<Object>} - Object containing video details and related videos
 */
export const getEnhancedVideoData = async (videoId, language = 'en', region = 'US') => {
  try {
    // Fetch both video details and related videos in parallel
    const [detailsResponse, relatedResponse] = await Promise.all([
      getVideoDetails(videoId, language),
      getRelatedVideos(videoId, language, region)
    ]);
    
    // Transform the data to match the format expected by the app
    const videoDetails = transformRapidApiVideo(detailsResponse);
    const relatedVideos = transformRelatedVideos(relatedResponse);
    
    return {
      videoDetails,
      relatedVideos
    };
  } catch (error) {
    console.error('Error fetching enhanced video data:', error);
    throw error;
  }
}; 