import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// Import but don't use trackPageView
import { trackPageView } from '../utils/analytics';

/**
 * Monitoring component that initializes and manages all monitoring services
 * This is a "silent" component that doesn't render anything visible
 */
const Monitoring = () => {
  const location = useLocation();

  // Track page views - DISABLED
  useEffect(() => {
    const path = location.pathname + location.search;
    const title = document.title;
    
    // Disabled trackPageView
    // trackPageView(path, title);
    
    // Log page view to console in development mode only
    if (import.meta.env.DEV) {
      console.log(`[Page View] ${path} - ${title}`);
    }
    
    // Sentry transaction code removed
  }, [location]);
  
  // Monitor and report errors
  useEffect(() => {
    const errorHandler = (event) => {
      console.error('Global error caught:', event.error || event.message);
      
      // Sentry reporting code removed
      
      // Try to report to Google Analytics if available
      try {
        if (window.gtag) {
          window.gtag('event', 'error', {
            'event_category': 'JavaScript Error',
            'event_label': (event.error || event.message).toString(),
            'non_interaction': true
          });
        }
      } catch (e) {
        console.warn('Failed to report error to Google Analytics:', e);
      }
    };
    
    window.addEventListener('error', errorHandler);
    
    return () => {
      window.removeEventListener('error', errorHandler);
    };
  }, [location]);
  
  // Set up performance monitoring
  useEffect(() => {
    if ('PerformanceObserver' in window) {
      // Monitor Largest Contentful Paint
      try {
        const lcpObserver = new PerformanceObserver((entryList) => {
          const entries = entryList.getEntries();
          const lastEntry = entries[entries.length - 1];
          
          if (lastEntry && window.gtag) {
            window.gtag('event', 'web_vitals', {
              metric_name: 'LCP',
              metric_value: lastEntry.startTime,
              metric_delta: 0
            });
          }
        });
        
        lcpObserver.observe({ type: 'largest-contentful-paint', buffered: true });
        
        return () => {
          lcpObserver.disconnect();
        };
      } catch (e) {
        console.warn('Failed to observe LCP:', e);
      }
    }
  }, []);

  return null;
};

export default Monitoring; 