import React, { useState, useEffect } from 'react';
import { Box, Tooltip, Badge, Typography } from '@mui/material';
import { getApiStatus, API_STATUS } from '../utils/fetchFromAPI';

const ApiStatusIndicator = () => {
  const [apiStatus, setApiStatus] = useState(getApiStatus());
  
  useEffect(() => {
    // Listen for API status changes
    const handleApiStatusChange = (event) => {
      setApiStatus(event.detail.status);
    };
    
    window.addEventListener('api-status-changed', handleApiStatusChange);
    
    return () => {
      window.removeEventListener('api-status-changed', handleApiStatusChange);
    };
  }, []);
  
  // Determine color and animation based on status
  const getStatusStyles = () => {
    switch (apiStatus) {
      case API_STATUS.ONLINE:
        return {
          color: '#4CAF50', // Green
          animation: 'pulse 2s infinite',
          title: 'API is online'
        };
      case API_STATUS.OFFLINE:
        return {
          color: '#F44336', // Red
          animation: 'pulse 1s infinite',
          title: 'API is offline'
        };
      default:
        return {
          color: '#FFC107', // Yellow
          animation: 'pulse 1.5s infinite',
          title: 'Checking API status'
        };
    }
  };
  
  const { color, animation, title } = getStatusStyles();
  
  return (
    <Tooltip title={title}>
      <Box 
        sx={{ 
          display: 'inline-flex',
          alignItems: 'center',
          ml: 2
        }}
      >
        <Box
          sx={{
            width: 12,
            height: 12,
            borderRadius: '50%',
            backgroundColor: color,
            animation: animation,
            '@keyframes pulse': {
              '0%': {
                opacity: 1,
                transform: 'scale(1)'
              },
              '50%': {
                opacity: 0.7,
                transform: 'scale(1.1)'
              },
              '100%': {
                opacity: 1,
                transform: 'scale(1)'
              }
            }
          }}
        />
        <Typography 
          variant="caption" 
          sx={{ 
            ml: 1,
            color: 'text.secondary',
            display: { xs: 'none', sm: 'block' }
          }}
        >
          {apiStatus === API_STATUS.ONLINE ? 'Live' : 
           apiStatus === API_STATUS.OFFLINE ? 'Offline' : 'Checking'}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default ApiStatusIndicator; 