/**
 * Utility functions for formatting various data types
 */

/**
 * Format view count to a human-readable string
 * @param {number|string} viewCount - The view count to format
 * @returns {string} Formatted view count
 */
export const formatViews = (viewCount) => {
  if (!viewCount) return '0 views';
  
  const count = typeof viewCount === 'string' ? Number.parseInt(viewCount, 10) : viewCount;
  
  if (count >= 1000000) {
    return `${(count / 1000000).toFixed(1)}M views`;
  }
  if (count >= 1000) {
    return `${(count / 1000).toFixed(1)}K views`;
  }
  return `${count} views`;
};

/**
 * Format ISO 8601 duration to a human-readable string
 * @param {string} duration - ISO 8601 duration string
 * @returns {string} Formatted duration
 */
export const formatDuration = (duration) => {
  if (!duration) return '0:00';
  const match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
  if (!match) return '0:00';
  
  const hours = (match[1] || '').replace('H', '');
  const minutes = (match[2] || '').replace('M', '');
  const seconds = (match[3] || '').replace('S', '');
  
  let result = '';
  if (hours) result += `${hours}:`;
  if (hours) {
    result += `${minutes.padStart(2, '0')}:`;
  } else {
    result += `${minutes || '0'}:`;
  }
  result += seconds.padStart(2, '0');
  return result;
};

/**
 * Format published date to a relative time string
 * @param {string} publishedAt - ISO date string
 * @returns {string} Relative time string
 */
export const formatPublishedAt = (publishedAt) => {
  if (!publishedAt) return '';
  try {
    const date = new Date(publishedAt);
    const now = new Date();
    const diffTime = Math.abs(now - date);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const diffMonths = Math.ceil(diffDays / 30);
    const diffYears = Math.ceil(diffDays / 365);

    if (diffDays < 1) {
      const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
      if (diffHours < 1) {
        const diffMinutes = Math.ceil(diffTime / (1000 * 60));
        return `${diffMinutes} minute${diffMinutes !== 1 ? 's' : ''} ago`;
      }
      return `${diffHours} hour${diffHours !== 1 ? 's' : ''} ago`;
    }
    if (diffDays === 1) return 'yesterday';
    if (diffDays < 30) return `${diffDays} day${diffDays !== 1 ? 's' : ''} ago`;
    if (diffMonths < 12) return `${diffMonths} month${diffMonths !== 1 ? 's' : ''} ago`;
    return `${diffYears} year${diffYears !== 1 ? 's' : ''} ago`;
  } catch (error) {
    console.error('Error formatting date:', error);
    return '';
  }
};

/**
 * Format like count to a human-readable string
 * @param {number|string} likeCount - The like count to format
 * @returns {string} Formatted like count
 */
export const formatLikes = (likeCount) => {
  if (!likeCount) return '0';
  
  const count = typeof likeCount === 'string' ? Number.parseInt(likeCount, 10) : likeCount;
  
  if (count >= 1000000) {
    return `${(count / 1000000).toFixed(1)}M`;
  }
  if (count >= 1000) {
    return `${(count / 1000).toFixed(1)}K`;
  }
  return count.toString();
};

/**
 * Format comment count to a human-readable string
 * @param {number|string} commentCount - The comment count to format
 * @returns {string} Formatted comment count
 */
export const formatComments = (commentCount) => {
  if (!commentCount) return '0';
  
  const count = typeof commentCount === 'string' ? Number.parseInt(commentCount, 10) : commentCount;
  
  if (count >= 1000000) {
    return `${(count / 1000000).toFixed(1)}M`;
  }
  if (count >= 1000) {
    return `${(count / 1000).toFixed(1)}K`;
  }
  return count.toString();
};

/**
 * Format a number with commas as thousands separators
 * @param {number|string} number - The number to format
 * @returns {string} Formatted number with commas
 */
export const formatNumberWithCommas = (number) => {
  if (!number) return '0';
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * Format file size in bytes to a human-readable string
 * @param {number} bytes - The file size in bytes
 * @param {number} decimals - Number of decimal places
 * @returns {string} Formatted file size
 */
export const formatFileSize = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  
  return `${Number.parseFloat((bytes / (k ** i)).toFixed(decimals))} ${sizes[i]}`;
}; 