import { useRef, useCallback, useEffect, useState } from 'react';
import { API_STATUS, getApiStatus } from './fetchFromAPI';

/**
 * Custom hook for throttling function calls
 * @param {Function} callback - The function to throttle
 * @param {number} delay - Throttle delay in milliseconds
 * @returns {Function} - Throttled function
 */
export const useThrottle = (callback, delay) => {
  const lastCall = useRef(0);
  const timeoutRef = useRef(null);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return useCallback(
    (...args) => {
      const now = Date.now();
      const timeSinceLastCall = now - lastCall.current;

      if (timeSinceLastCall >= delay) {
        lastCall.current = now;
        callback(...args);
      } else {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
          lastCall.current = Date.now();
          callback(...args);
        }, delay - timeSinceLastCall);
      }
    },
    [callback, delay]
  );
};

/**
 * Custom hook for debouncing function calls
 * @param {Function} callback - The function to debounce
 * @param {number} delay - Debounce delay in milliseconds
 * @returns {Function} - Debounced function
 */
export const useDebounce = (callback, delay) => {
  const timeoutRef = useRef(null);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return useCallback(
    (...args) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay]
  );
};

/**
 * Custom hook for memoizing expensive calculations
 * @param {Function} computeFunc - The expensive computation function
 * @param {Array} dependencies - Dependencies array for recomputation
 * @returns {any} - Computed value
 */
export const useMemoizedComputation = (computeFunc, dependencies) => {
  const cache = useRef({
    deps: null,
    result: null
  });

  if (
    !cache.current.deps ||
    dependencies.length !== cache.current.deps.length ||
    dependencies.some((dep, i) => dep !== cache.current.deps[i])
  ) {
    cache.current.deps = dependencies;
    cache.current.result = computeFunc();
  }

  return cache.current.result;
};

/**
 * Custom hook to track API data source status
 * @returns {Object} The current API status and a function to check if using real data
 */
export const useApiStatus = () => {
  const [status, setStatus] = useState(getApiStatus());
  
  useEffect(() => {
    const handleApiStatusChange = (event) => {
      setStatus(event.detail.status);
    };
    
    window.addEventListener('api-status-changed', handleApiStatusChange);
    
    return () => {
      window.removeEventListener('api-status-changed', handleApiStatusChange);
    };
  }, []);
  
  const isUsingRealData = useCallback(() => {
    return status === API_STATUS.ONLINE;
  }, [status]);
  
  const isUsingFallbackData = useCallback(() => {
    return status === API_STATUS.OFFLINE;
  }, [status]);
  
  return { 
    status, 
    isUsingRealData, 
    isUsingFallbackData,
    isLoading: status === API_STATUS.LOADING
  };
};

/**
 * Custom hook for detecting network status
 * @returns {boolean} - Whether the user is online
 */
export const useNetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return isOnline;
};

/**
 * Alias for useNetworkStatus for backward compatibility
 * @returns {boolean} - Whether the user is online
 */
export const useOnlineStatus = useNetworkStatus;

/**
 * Custom hook for measuring component render time
 * @param {string} componentName - Name of the component
 * @param {boolean} enabled - Whether to enable the measurement
 * @returns {void}
 */
export const useRenderTime = (componentName, enabled = true) => {
  const startTime = useRef(performance.now());

  useEffect(() => {
    if (!enabled) return;
    
    const endTime = performance.now();
    const renderTime = endTime - startTime.current;
    
    console.log(`[Performance] ${componentName} rendered in ${renderTime.toFixed(2)}ms`);
    
    // Report to analytics if render time is high
    if (renderTime > 200) {
      if (window.gtag) {
        window.gtag('event', 'slow_render', {
          component: componentName,
          render_time: renderTime
        });
      }
    }
    
    return () => {
      startTime.current = performance.now();
    };
  });
}; 