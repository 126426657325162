import React, { useEffect, useState, useCallback, useRef } from "react";
import { Box, Stack, Typography, CircularProgress, Chip, Collapse, IconButton, Paper, Slider, Switch, FormControlLabel, Alert, Tooltip, Badge, Button } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import BugReportIcon from '@mui/icons-material/BugReport';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CachedIcon from '@mui/icons-material/Cached';
import TuneIcon from '@mui/icons-material/Tune';
import { Analytics } from "@vercel/analytics/react";
import { useThrottle } from "../utils/hooks";
import { logEvent } from "../utils/analytics";

import { fetchFromAPI, checkRapidApiSubscription, API_STATUS, getApiStatus, getUserCountry } from "../utils/fetchFromAPI";
import { Videos, Sidebar } from "./";
import { VideoGrid, Loader } from './';
import { trackEvent } from "./GoogleAnalytics";
import { useAuth } from "../utils/AuthContext";
import { enhanceVideosWithStats, getLenientMotorcycleVideos } from "../utils/videoUtils";

// Create a more robust cache with TTL and size limits 
class AdvancedCache {
  constructor(maxSize = 100, defaultTTL = 60 * 60 * 1000) {
    this.cache = new Map();
    this.maxSize = maxSize;
    this.defaultTTL = defaultTTL;
    this.stats = {
      hits: 0,
      misses: 0,
      evictions: 0
    };
  }

  set(key, value, ttl = this.defaultTTL) {
    // Evict oldest items if cache is full
    if (this.cache.size >= this.maxSize) {
      const oldestKey = this.getOldestKey();
      if (oldestKey) {
        this.cache.delete(oldestKey);
        this.stats.evictions++;
      }
    }

    this.cache.set(key, {
      value,
      expiry: Date.now() + ttl,
      createdAt: Date.now()
    });
  }

  get(key) {
    const item = this.cache.get(key);
    
    if (!item) {
      this.stats.misses++;
      return null;
    }
    
    if (Date.now() > item.expiry) {
      this.cache.delete(key);
      this.stats.misses++;
      return null;
    }
    
    this.stats.hits++;
    return item.value;
  }

  getOldestKey() {
    let oldestKey = null;
    let oldestTime = Number.POSITIVE_INFINITY;
    
    for (const [key, item] of this.cache.entries()) {
      if (item.createdAt < oldestTime) {
        oldestTime = item.createdAt;
        oldestKey = key;
      }
    }
    
    return oldestKey;
  }

  clear() {
    this.cache.clear();
  }

  getStats() {
    return {
      ...this.stats,
      size: this.cache.size,
      hitRate: this.stats.hits / (this.stats.hits + this.stats.misses) || 0
    };
  }
}

// Update the RateLimiter class to be more permissive and reset properly
class RateLimiter {
  constructor(maxTokens = 50, refillRate = 5, refillInterval = 1000) {
    this.maxTokens = maxTokens;
    this.tokens = maxTokens; // Start with full tokens
    this.refillRate = refillRate; // tokens per interval
    this.lastRefill = Date.now();
    this.refillInterval = refillInterval; // ms
    
    // Start token refill process
    this.refillIntervalId = setInterval(() => this.refill(), refillInterval);
    
    // Force reset every minute to prevent stuck state
    this.resetIntervalId = setInterval(() => this.reset(), 60000);
  }

  refill() {
    const now = Date.now();
    const timePassed = now - this.lastRefill;
    const tokensToAdd = Math.floor(timePassed / this.refillInterval) * this.refillRate;
    
    if (tokensToAdd > 0) {
      this.tokens = Math.min(this.maxTokens, this.tokens + tokensToAdd);
      this.lastRefill = now;
    }
  }
  
  // Add a reset method to force reset the tokens
  reset() {
    console.log("Rate limiter reset - restoring full tokens");
    this.tokens = this.maxTokens;
    this.lastRefill = Date.now();
  }

  canMakeRequest() {
    this.refill();
    // Always allow at least one request
    return this.tokens >= 1 || PERFORMANCE_METRICS.apiCalls === 0;
  }

  consumeToken() {
    if (this.tokens >= 1) {
      this.tokens--;
      return true;
    }
    // If no tokens but it's the first request, allow it anyway
    if (PERFORMANCE_METRICS.apiCalls === 0) {
      return true;
    }
    return false;
  }

  destroy() {
    clearInterval(this.refillIntervalId);
    clearInterval(this.resetIntervalId);
  }
}

// Initialize cache and rate limiter
const apiCache = new AdvancedCache(200, 30 * 60 * 1000); // 200 items, 30 min TTL
const rateLimiter = new RateLimiter(50, 5, 1000); // 50 max tokens, refill 5 tokens per second

// Performance monitoring
const PERFORMANCE_METRICS = {
  apiCalls: 0,
  cacheHits: 0,
  cacheMisses: 0,
  renderTime: 0,
  apiResponseTime: {},
  errors: 0
};

// Enhanced official channel IDs with multiple channels per category
const OFFICIAL_CHANNEL_IDS = {
  'AMA Supercross': [
    'UCkaNo2FUEWips2z4BkOHl6Q', // SupercrossLive
    'UCnXXULCsQWkYRO_-9Xhpfrg', // Monster Energy Supercross
    'UCJZUFOkQC-jL9FeRfzYpTGQ'  // AMA Pro Racing
  ],
  'Supercross': [
    'UCkaNo2FUEWips2z4BkOHl6Q', // SupercrossLive
    'UCnXXULCsQWkYRO_-9Xhpfrg', // Monster Energy Supercross
    'UCJZUFOkQC-jL9FeRfzYpTGQ', // AMA Pro Racing
    'UCpz_0gjWO8J5RnNjKe9YLMw'  // MXGP-TV (also covers Supercross)
  ],
  'Motocross': [
    'UC_gkm2w-CpdXbFPRgYk5Qkw', // Pro Motocross
    'UCJZUFOkQC-jL9FeRfzYpTGQ', // AMA Pro Racing
    'UCNHm2I4VILXvvANDvrH9Kdg', // Red Bull Motorsports
    'UCpz_0gjWO8J5RnNjKe9YLMw'  // MXGP-TV
  ],
  'MXGP': [
    'UCpz_0gjWO8J5RnNjKe9YLMw', // MXGP-TV
    'UCwKq0MyS67yGUcLLjynh7Cg', // MXGP Official
    'UCNHm2I4VILXvvANDvrH9Kdg'  // Red Bull Motorsports
  ],
  'Hard Enduro': [
    'UCNHm2I4VILXvvANDvrH9Kdg', // Red Bull Motorsports
    'UCMhviVG-HYnlNyJqHQeesJQ', // EnduroGP
    'UCpHevnc2S9f_6V1CxipmFpA', // Hard Enduro
    'UCnTlS7c7_UKMy8RNTQw-0lA'  // EnduroCross
  ],
  'Moto Freestyle': [
    'UCblfuW_4rakIf2h6aqANefA', // X Games
    'UCNHm2I4VILXvvANDvrH9Kdg', // Red Bull Motorsports
    'UCXuqSBlHAE6Xw-yeJA0Tunw', // Nitro Circus
    'UC0sMZ0Uv9Jh0Vw-qcnMpUQ'   // FMX World
  ],
  'Moto Freeride': [
    'UCNHm2I4VILXvvANDvrH9Kdg', // Red Bull Motorsports
    'UCXuqSBlHAE6Xw-yeJA0Tunw', // Nitro Circus
    'UCpHevnc2S9f_6V1CxipmFpA'  // Hard Enduro (covers freeride too)
  ],
  'Trials': [
    'UCNHm2I4VILXvvANDvrH9Kdg', // Red Bull Motorsports
    'UCMhviVG-HYnlNyJqHQeesJQ', // EnduroGP (covers trials)
    'UC-IfTk1eLJgXPUE2NiGBIKQ'  // FIM Trials
  ],
  'Motobike Fails': [
    'UCPDis9pjXuqyI7RYLJ-TTSA', // FailArmy
    'UCsqjHFMB_JYTaEnf_vmTNqg', // Moto Madness
    'UCo-YNBzUQQIJ7_9HvBrCL7g'  // Moto Compilations
  ],
  'Motorcycle Stunts': [
    'UCNHm2I4VILXvvANDvrH9Kdg', // Red Bull Motorsports
    'UCXuqSBlHAE6Xw-yeJA0Tunw', // Nitro Circus
    'UCblfuW_4rakIf2h6aqANefA'  // X Games
  ],
  'Motorcycle Maintenance': [
    'UCrRR5mU5TQfersKGBILSsMQ', // RevZilla
    'UCvO9Xk3mgXDeGQsLyW-WSPg', // MC Garage
    'UCG20mxB_aLRQ-2RAfIElpKg'  // Fortnine
  ],
  'Motorcycle Reviews': [
    'UCrRR5mU5TQfersKGBILSsMQ', // RevZilla
    'UCG20mxB_aLRQ-2RAfIElpKg', // Fortnine
    'UCiU1-5zzFupFj1Ufgp1TMdA'  // Motorcycle.com
  ],
  'Motorcycle Racing': [
    'UCNHm2I4VILXvvANDvrH9Kdg', // Red Bull Motorsports
    'UCJZUFOkQC-jL9FeRfzYpTGQ', // AMA Pro Racing
    'UCpz_0gjWO8J5RnNjKe9YLMw', // MXGP-TV
    'UC7u5gjGrOSBqWUVKsn9xYIQ'  // MotoGP
  ],
  'Motorcycle Tutorials': [
    'UCrRR5mU5TQfersKGBILSsMQ', // RevZilla
    'UCG20mxB_aLRQ-2RAfIElpKg', // Fortnine
    'UCvO9Xk3mgXDeGQsLyW-WSPg'  // MC Garage
  ],
};

// Video category IDs for YouTube API
const VIDEO_CATEGORY_IDS = {
  'Sports': '17',
  'Autos & Vehicles': '2'
};

// Blacklisted channels that often have misleading content
const BLACKLISTED_CHANNELS = [
  'UCsAegdhiYLEoIri8vHchA3w', // Clickbait motorcycle channel
  'UC-yXuc1__OzjwpsJPlxYUCQ', // Compilation channel with misleading titles
];

// Blacklisted keywords that indicate irrelevant content
const BLACKLISTED_KEYWORDS = [
  'gta', 'grand theft auto', 'video game', 'gameplay', 'gaming',
  'animation', 'cartoon', 'toy', 'rc', 'remote control',
  'unboxing', 'review', 'reaction', 'react', 'reacting'
];

const Feed = () => {
  const [selectedCategory, setSelectedCategory] = useState("Supercross");
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDebug, setShowDebug] = useState(false);
  const [debugInfo, setDebugInfo] = useState({});
  const [relevanceThreshold, setRelevanceThreshold] = useState(5); // Increased threshold
  const [isStrictFiltering, setIsStrictFiltering] = useState(true); // Set to true by default
  const lastFetchTime = useRef(0);
  const [apiLimitReached, setApiLimitReached] = useState(false);
  const [useMultipleChannels, setUseMultipleChannels] = useState(true);
  const [useBlacklist, setUseBlacklist] = useState(true);
  const [useStrictTitleFiltering, setUseStrictTitleFiltering] = useState(true);
  const [debugVideos, setDebugVideos] = useState([]);
  const [performanceMetrics, setPerformanceMetrics] = useState({});
  const [isPerformanceMonitoringEnabled, setIsPerformanceMonitoringEnabled] = useState(false);
  const renderStartTime = useRef(0);
  const apiCallTimes = useRef({});
  const [apiStatus, setApiStatus] = useState(getApiStatus());
  const { isAuthenticated } = useAuth();
  const [userCountry, setUserCountry] = useState(getUserCountry());
  
  // Use throttled category selection to prevent excessive re-renders
  const throttledSetCategory = useThrottle((category) => {
    setSelectedCategory(category);
    // Track category selection for analytics
    logEvent('category_selected', { category });
  }, 300);
  
  // Check API status on component mount
  useEffect(() => {
    const checkApiStatus = async () => {
      try {
        const result = await checkRapidApiSubscription();
        console.log("API subscription check result:", result);
        
        if (!result.valid) {
          console.error("API subscription check failed:", result.error);
          setError(`API connection failed: ${result.error}`);
          setApiLimitReached(true);
        }
      } catch (error) {
        console.error("Error checking API status:", error);
      }
    };
    
    checkApiStatus();
    
    // Listen for API status changes
    const handleApiStatusChange = (event) => {
      setApiStatus(event.detail.status);
      if (event.detail.status === API_STATUS.OFFLINE) {
        setApiLimitReached(true);
      } else if (event.detail.status === API_STATUS.ONLINE) {
        setApiLimitReached(false);
      }
    };
    
    window.addEventListener('api-status-changed', handleApiStatusChange);
    
    return () => {
      window.removeEventListener('api-status-changed', handleApiStatusChange);
    };
  }, []);
  
  // Cleanup on component unmount
  useEffect(() => {
    return () => {
      rateLimiter.destroy();
    };
  }, []);
  
  // Performance monitoring
  useEffect(() => {
    if (isPerformanceMonitoringEnabled) {
      const interval = setInterval(() => {
        setPerformanceMetrics({
          ...PERFORMANCE_METRICS,
          cacheStats: apiCache.getStats(),
          timestamp: new Date().toISOString()
        });
        
        // Send metrics to analytics service every minute
        logEvent('performance_metrics', {
          ...PERFORMANCE_METRICS,
          cacheStats: apiCache.getStats()
        });
      }, 60000); // Update every minute
      
      return () => clearInterval(interval);
    }
  }, [isPerformanceMonitoringEnabled]);
  
  // Measure render time
  useEffect(() => {
    renderStartTime.current = performance.now();
    
    return () => {
      const renderTime = performance.now() - renderStartTime.current;
      PERFORMANCE_METRICS.renderTime = renderTime;
    };
  }, []); // Only run on mount and unmount
  
  // Listen for country detection updates
  useEffect(() => {
    const handleCountryDetection = (event) => {
      setUserCountry(event.detail.country);
      // Refetch videos when country is detected
      if (selectedCategory) {
        fetchVideosForCategory(selectedCategory);
      }
    };
    
    window.addEventListener('country-detected', handleCountryDetection);
    
    return () => {
      window.removeEventListener('country-detected', handleCountryDetection);
    };
  }, [selectedCategory]);
  
  // Add useEffect to trigger video fetching when component mounts or category changes
  useEffect(() => {
    console.log("Feed component mounted or category changed:", selectedCategory);
    fetchVideosForCategory();
  }, [selectedCategory]);
  
  // Enhanced API fetching with caching, rate limiting, and error handling
  const enhancedFetchFromAPI = async (endpoint) => {
    try {
      // Check cache first
      const cacheKey = endpoint;
      const cachedData = apiCache.get(cacheKey);
      
      if (cachedData) {
        PERFORMANCE_METRICS.cacheHits++;
        return cachedData;
      }
      
      PERFORMANCE_METRICS.cacheMisses++;
    
      // Check if we're already at the API limit
      if (apiLimitReached) {
        console.warn("API rate limit already reached, using fallback data");
        
        // Try to find similar cached results
        const similarCacheKeys = Array.from(apiCache.cache.keys())
          .filter(key => key.includes(endpoint.split('&')[0]));
          
        if (similarCacheKeys.length > 0) {
          console.log("Using similar cached results due to rate limit");
          return apiCache.get(similarCacheKeys[0]);
        }
        
        // If no similar cache, check if this is a search endpoint
        if (endpoint.includes('search')) {
          // Return mock data for search endpoints
          return { 
            items: MOCK_DATA.search.items.map(item => ({
              ...item,
              // Add some randomness to make results look different
              id: { 
                ...item.id,
                videoId: `${item.id.videoId}_${Math.floor(Math.random() * 1000)}`
              }
            }))
          };
        }
        
        // Last resort - return empty results
        return { items: [] };
      }
    
      // Check rate limiting
      if (!rateLimiter.canMakeRequest()) {
        console.warn("API rate limit reached, waiting before making request");
        
        // Wait for tokens to refill (at least 1 second)
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        // Check again after waiting
        if (!rateLimiter.canMakeRequest()) {
          console.error("API rate limit still reached after waiting, using fallback");
          setApiLimitReached(true);
          
          // Return mock data for search endpoints
          if (endpoint.includes('search')) {
            return { items: MOCK_DATA.search.items };
          }
          
          // Last resort - return empty results
          return { items: [] };
        }
      }
    
      // Consume a token and make the API call
      rateLimiter.consumeToken();
      PERFORMANCE_METRICS.apiCalls++;
      
      // Track API call time
      const apiCallStartTime = performance.now();
      apiCallTimes.current[endpoint] = apiCallStartTime;
      
      try {
        const data = await fetchFromAPI(endpoint);
        
        // If we get here, reset the API limit reached flag
        if (apiLimitReached) {
          setApiLimitReached(false);
        }
        
        // Calculate and store API response time
        const apiCallEndTime = performance.now();
        const responseTime = apiCallEndTime - apiCallTimes.current[endpoint];
        PERFORMANCE_METRICS.apiResponseTime[endpoint] = responseTime;
        
        // Cache the result
        apiCache.set(cacheKey, data);
        
        return data;
      } catch (error) {
        // If this is a rate limit error, handle it specially
        if (error.response && (error.response.status === 429 || error.response.status === 403)) {
          console.error("API rate limit error from the server");
          setApiLimitReached(true);
          
          // Check if the fetchFromAPI already returned mock data
          if (error.mockData) {
            console.log("Using mock data from fetchFromAPI");
            return error.mockData;
          }
          
          // If we have cached results for any similar query, use those
          const similarCacheKeys = Array.from(apiCache.cache.keys())
            .filter(key => key.includes(endpoint.split('&')[0]));
            
          if (similarCacheKeys.length > 0) {
            console.log("Using similar cached results due to rate limit");
            return apiCache.get(similarCacheKeys[0]);
          }
          
          // Return mock data for search endpoints
          if (endpoint.includes('search')) {
            return { items: MOCK_DATA.search.items };
          }
          
          // Last resort - return empty results
          return { items: [] };
        }
        
        // Re-throw other errors
        throw error;
      }
    } catch (error) {
      PERFORMANCE_METRICS.errors++;
      
      // Log error to console
      console.error(`API call failed: ${endpoint}`, error);
      
      // Log to analytics
      logEvent('api_error', {
        endpoint,
        error: error.message
      });
      
      // Return empty results instead of throwing
      return { items: [] };
    }
  };

  const fetchVideosForCategory = async (category = selectedCategory) => {
    setLoading(true);
    setError(null);
    
    try {
      // Construct a more targeted search query based on the category
      let searchTerm;
      const maxResults = 50;
      const currentYear = new Date().getFullYear();
      
      // Customize search terms based on category for better results
      switch(category) {
        case 'Supercross':
          searchTerm = `supercross ${currentYear} race full`;
          break;
        case 'AMA Supercross':
          searchTerm = `ama supercross ${currentYear} race full`;
          break;
        case 'Motocross':
          searchTerm = `motocross ${currentYear} race mx`;
          break;
        case 'MXGP':
          searchTerm = `mxgp ${currentYear} race world championship`;
          break;
        case 'Hard Enduro':
          searchTerm = `hard enduro ${currentYear} race`;
          break;
        case 'Moto Freestyle':
          searchTerm = 'motorcycle freestyle fmx x games';
          break;
        case 'Moto Freeride':
          searchTerm = 'motorcycle freeride enduro';
          break;
        case 'Trials':
          searchTerm = 'motorcycle trials competition';
          break;
        case 'Motobike Fails':
          searchTerm = 'motorcycle crash fail compilation';
          break;
        case 'Motorcycle Stunts':
          searchTerm = 'motorcycle stunts wheelie';
          break;
        case 'Motorcycle Maintenance':
          searchTerm = 'motorcycle maintenance repair tutorial';
          break;
        case 'Motorcycle Reviews':
          searchTerm = `motorcycle review test ride ${currentYear}`;
          break;
        case 'Motorcycle Racing':
          searchTerm = `motorcycle racing road race ${currentYear}`;
          break;
        case 'Motorcycle Tutorials':
          searchTerm = 'motorcycle how to tutorial tips';
          break;
        case 'New':
          searchTerm = `motorcycle new ${currentYear}`;
          break;
        default:
          searchTerm = `${category} motorcycle`;
      }
      
      console.log(`Searching for: ${searchTerm} in category: ${category}`);
      
      // Try to get videos from official channels first if available for this category
      const officialChannelVideos = [];
      
      if (OFFICIAL_CHANNEL_IDS[category] && useMultipleChannels) {
        console.log(`Fetching from official channels for ${category}`);
        
        // Fetch videos from each official channel
        const channelPromises = OFFICIAL_CHANNEL_IDS[category].map(channelId => 
          fetchFromAPI(`search?part=snippet&channelId=${channelId}&order=date&type=video&maxResults=10`)
            .then(data => data?.items || [])
            .catch(err => {
              console.warn(`Error fetching from channel ${channelId}:`, err);
              return [];
            })
        );
        
        const channelResults = await Promise.all(channelPromises);
        for (const results of channelResults) {
          officialChannelVideos.push(...results);
        }
        
        console.log(`Found ${officialChannelVideos.length} videos from official channels`);
      }
      
      // Fetch general search results
      const data = await fetchFromAPI(`search?part=snippet&q=${searchTerm}&type=video&maxResults=${maxResults}&videoCategoryId=${VIDEO_CATEGORY_IDS.Sports}`);
      
      if (!data || !data.items || data.items.length === 0) {
        // If no general results but we have official channel videos, use those
        if (officialChannelVideos.length > 0) {
          console.log(`Using ${officialChannelVideos.length} videos from official channels`);
          
          // Filter and enhance videos
          const filteredVideos = getLenientMotorcycleVideos(officialChannelVideos, userCountry, category);
          const enhancedVideos = enhanceVideosWithStats(filteredVideos);
          setVideos(enhancedVideos);
          setLoading(false);
          return;
        }
        
        // Try a fallback search without category ID
        console.log('No results found with category ID, trying without category restriction');
        const fallbackData = await fetchFromAPI(`search?part=snippet&q=${searchTerm}&type=video&maxResults=${maxResults}`);
        
        if (!fallbackData || !fallbackData.items || fallbackData.items.length === 0) {
          setError(`No motorcycle videos found for ${category}`);
          setVideos([]);
          setLoading(false);
          return;
        }
        
        // Use the fallback data
        data.items = fallbackData.items;
      }
      
      console.log(`Fetched ${data.items.length} videos for ${category} in ${userCountry}`);
      
      // Store original videos for debugging
      setDebugVideos(data.items);
      
      // Combine official channel videos with search results, prioritizing official ones
      const combinedVideos = [...officialChannelVideos];
      
      // Add search results, avoiding duplicates
      const existingIds = new Set(combinedVideos.map(v => v.id.videoId));
      for (const video of data.items) {
        if (!existingIds.has(video.id.videoId)) {
          combinedVideos.push(video);
          existingIds.add(video.id.videoId);
        }
      }
      
      console.log(`Combined ${combinedVideos.length} videos (${officialChannelVideos.length} official + ${data.items.length} search)`);
      
      // Filter videos to get motorcycle content available in user's region
      // Use category-specific filtering if available
      let filteredVideos;
      
      // Apply category-specific filtering
      if (category === 'Motorcycle Maintenance' || category === 'Motorcycle Reviews' || category === 'Motorcycle Tutorials') {
        // For informational content, we want to be more lenient with filtering
        filteredVideos = filterRegionRestrictedVideos(combinedVideos, userCountry);
      } else {
        // For racing/action content, use the standard motorcycle filtering with category
        filteredVideos = getLenientMotorcycleVideos(combinedVideos, userCountry, category);
      }
      
      console.log(`After filtering: ${filteredVideos.length} videos remain`);
      
      if (filteredVideos.length === 0) {
        // If no videos remain after filtering, try a more general search
        console.log("No videos after filtering, trying more general search");
        
        // Use a category-specific fallback search term
        let fallbackSearchTerm;
        switch(category) {
          case 'Supercross':
          case 'AMA Supercross':
            fallbackSearchTerm = 'supercross race';
            break;
          case 'Motocross':
            fallbackSearchTerm = 'motocross race';
            break;
          case 'MXGP':
            fallbackSearchTerm = 'mxgp motocross';
            break;
          case 'Hard Enduro':
            fallbackSearchTerm = 'hard enduro';
            break;
          default:
            fallbackSearchTerm = 'motorcycle';
        }
        
        const generalData = await fetchFromAPI(`search?part=snippet&q=${fallbackSearchTerm}&type=video&maxResults=50`);
        
        if (generalData?.items?.length > 0) {
          const generalFiltered = getLenientMotorcycleVideos(generalData.items, userCountry, category);
          
          if (generalFiltered.length > 0) {
            console.log(`Found ${generalFiltered.length} videos from general search`);
            // Enhance videos with statistics
            const enhancedVideos = enhanceVideosWithStats(generalFiltered);
            setVideos(enhancedVideos);
            setLoading(false);
            return;
          }
        }
        
        setError(`No motorcycle videos found for ${category} that are available in your region`);
        setVideos([]);
        setLoading(false);
        return;
      }
      
      // Enhance videos with statistics
      const enhancedVideos = enhanceVideosWithStats(filteredVideos);
      setVideos(enhancedVideos);
    } catch (error) {
      console.error("Error fetching videos:", error);
      setError(`Failed to load videos: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };
  
  // Enhanced debug panel with performance metrics
  const DebugPanel = () => (
    <Collapse in={showDebug && (import.meta.env.MODE === 'development' || isAuthenticated)}>
      <Paper 
        sx={{ 
          p: 2, 
          mb: 2, 
          bgcolor: 'rgba(0,0,0,0.8)', 
          color: 'white',
          border: '1px solid #333'
        }}
      >
        <Typography variant="h6" gutterBottom>Debug Information</Typography>
        <Typography variant="body2">Category: {selectedCategory}</Typography>
        <Typography variant="body2">Total videos before filtering: {debugVideos.length}</Typography>
        <Typography variant="body2">Videos after filtering: {videos.length}</Typography>
        
        <Box mt={2}>
          <Typography variant="subtitle2">API Status:</Typography>
          <Typography variant="body2" color={apiLimitReached ? "error" : "success"}>
            API Rate Limit: {apiLimitReached ? "Reached" : "OK"}
          </Typography>
          <Typography variant="body2">
            Rate Limiter Tokens: {rateLimiter.tokens}/{rateLimiter.maxTokens}
          </Typography>
          <Typography variant="body2">
            Cache Size: {apiCache.cache.size} items
          </Typography>
          <Button 
            variant="outlined" 
            size="small" 
            color="error" 
            sx={{ mt: 1, mr: 1 }}
            onClick={() => {
              apiCache.clear();
              console.log("Cache cleared");
            }}
          >
            Clear Cache
          </Button>
          <Button 
            variant="outlined" 
            size="small" 
            color="primary" 
            sx={{ mt: 1 }}
            onClick={() => {
              rateLimiter.reset();
              setApiLimitReached(false);
              console.log("Rate limiter reset");
            }}
          >
            Reset Rate Limiter
          </Button>
        </Box>
        
        <Box mt={2}>
          <Typography variant="subtitle2">Filtering Options:</Typography>
          <FormControlLabel
            control={
              <Switch
                checked={useMultipleChannels}
                onChange={(e) => setUseMultipleChannels(e.target.checked)}
                color="primary"
              />
            }
            label="Use Multiple Official Channels"
          />
          
          <FormControlLabel
            control={
              <Switch
                checked={useBlacklist}
                onChange={(e) => setUseBlacklist(e.target.checked)}
                color="primary"
              />
            }
            label="Use Blacklist Filtering"
          />
          
          <FormControlLabel
            control={
              <Switch
                checked={useStrictTitleFiltering}
                onChange={(e) => setUseStrictTitleFiltering(e.target.checked)}
                color="primary"
              />
            }
            label="Strict Title Filtering"
          />
          
          <FormControlLabel
            control={
              <Switch
                checked={isPerformanceMonitoringEnabled}
                onChange={(e) => setIsPerformanceMonitoringEnabled(e.target.checked)}
                color="primary"
              />
            }
            label="Performance Monitoring"
          />
        </Box>
        
        {isPerformanceMonitoringEnabled && (
          <Box mt={2}>
            <Typography variant="subtitle2">Performance Metrics:</Typography>
            <Typography variant="body2">API Calls: {PERFORMANCE_METRICS.apiCalls}</Typography>
            <Typography variant="body2">Cache Hit Rate: {(apiCache.getStats().hitRate * 100).toFixed(2)}%</Typography>
            <Typography variant="body2">Render Time: {PERFORMANCE_METRICS.renderTime.toFixed(2)}ms</Typography>
            <Typography variant="body2">Errors: {PERFORMANCE_METRICS.errors}</Typography>
          </Box>
        )}
        
        <Typography variant="subtitle2" mt={2}>Sample Videos Before Filtering:</Typography>
        {debugVideos.slice(0, 3).map((video, idx) => (
          <Typography key={`debug-${idx}-${video.id.videoId || idx}`} variant="body2" sx={{ fontSize: '0.8rem' }}>
            {video.snippet.title} - {video.snippet.channelTitle}
          </Typography>
        ))}
        
        <Typography variant="subtitle2" mt={2}>Sample Videos After Filtering:</Typography>
        {videos.slice(0, 3).map((video, idx) => (
          <Typography key={`filtered-${idx}-${video.id.videoId || idx}`} variant="body2" sx={{ fontSize: '0.8rem' }}>
            {video.snippet.title} - {video.snippet.channelTitle}
          </Typography>
        ))}
      </Paper>
    </Collapse>
  );

  return (
    <Stack sx={{ flexDirection: { xs: "column", md: "row" } }}>
      <Box sx={{ 
        height: { xs: "auto", md: "92vh" }, 
        borderRight: "1px solid #3d3d3d", 
        px: { xs: 0, md: 2 },
        width: { xs: "100%", md: "270px" }
      }}>
        <Sidebar selectedCategory={selectedCategory} setSelectedCategory={throttledSetCategory} />
      </Box>

      <Box p={2} sx={{ overflowY: "auto", height: "90vh", flex: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h4" fontWeight="bold" sx={{ color: "white" }}>
          {selectedCategory} <span style={{ color: "#FC1503" }}>videos</span>
        </Typography>

          {(import.meta.env.MODE === 'development' || isAuthenticated) && (
            <Box>
              <IconButton 
                onClick={() => {
                  console.log("Forcing refresh of videos");
                  // Clear cache for this category
                  const keysToDelete = Array.from(apiCache.cache.keys())
                    .filter(key => key.includes(selectedCategory.toLowerCase()));
                  for (const key of keysToDelete) {
                    apiCache.cache.delete(key);
                  }
                  // Reset rate limiter
                  rateLimiter.reset();
                  // Fetch videos again
                  fetchVideosForCategory();
                  alert("Refreshing videos");
                }} 
                sx={{ color: 'gray', mr: 1 }}
                aria-label="Refresh videos"
              >
                <CachedIcon />
              </IconButton>
              <IconButton 
                onClick={() => {
                  console.log("Debug info:");
                  console.log("Videos:", videos);
                  console.log("API Cache:", apiCache);
                  console.log("Rate Limiter:", rateLimiter);
                  console.log("Performance Metrics:", PERFORMANCE_METRICS);
                  console.log("Debug Videos:", debugVideos);
                  alert("Debug info logged to console");
                }} 
                sx={{ color: 'gray', mr: 1 }}
                aria-label="Debug info"
              >
                <InfoIcon />
              </IconButton>
              <IconButton 
                onClick={() => setShowDebug(!showDebug)} 
                sx={{ color: 'gray' }}
                aria-label="Toggle debug panel"
              >
                <BugReportIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        
        <DebugPanel />

        {apiLimitReached && (
          <Alert severity="warning" sx={{ mb: 2 }}>
            API call limit reached. The YouTube API has rate-limited our requests. We're showing cached or fallback content.
            <Box sx={{ mt: 1 }}>
              <Button 
                variant="contained" 
                color="primary" 
                size="small" 
                sx={{ mr: 1 }}
                onClick={() => {
                  rateLimiter.reset();
                  setApiLimitReached(false);
                  fetchVideosForCategory();
                }}
              >
                Try Again
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={() => {
                  // Switch to a different category to potentially use cached data
                  const categories = ['Supercross', 'Motocross', 'Hard Enduro', 'MXGP', 'Moto Freestyle'];
                  const currentIndex = categories.indexOf(selectedCategory);
                  const nextCategory = categories[(currentIndex + 1) % categories.length];
                  throttledSetCategory(nextCategory);
                }}
              >
                Try Different Category
              </Button>
            </Box>
          </Alert>
        )}

        {loading && (
          <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
            <CircularProgress color="error" />
          </Box>
        )}

        {error && (
          <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
            <Typography color="error" variant="h6">{error}</Typography>
          </Box>
        )}

        {!loading && !error && videos && (
          <>
            <VideoGrid videos={videos} />
            {videos.length === 0 && (
              <Typography color="white" variant="h6" textAlign="center" mt={4}>
                No motorcycle videos found for this category. Try another category.
              </Typography>
            )}
          </>
        )}
      </Box>
    </Stack>
  );
};

export default Feed;