import React, { createContext, useState, useContext, useEffect } from 'react';

// Create the authentication context
const AuthContext = createContext();

// Admin credentials - in a real app, these would be stored securely on a server
// For demo purposes, we're using simple hardcoded credentials
const ADMIN_USERNAME = 'admin';
const ADMIN_PASSWORD = 'motovids123';

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // Check if user is already logged in from localStorage
  useEffect(() => {
    const storedUser = localStorage.getItem('motovids_admin');
    if (storedUser) {
      try {
        const parsedUser = JSON.parse(storedUser);
        setUser(parsedUser);
        setIsAuthenticated(true);
      } catch (error) {
        console.error('Error parsing stored user:', error);
        localStorage.removeItem('motovids_admin');
      }
    }
  }, []);

  // Login function
  const login = async (username, password) => {
    setLoading(true);
    setError(null);

    try {
      // Simulate API call delay
      await new Promise(resolve => setTimeout(resolve, 500));

      // Check credentials
      if (username === ADMIN_USERNAME && password === ADMIN_PASSWORD) {
        const userData = { username, role: 'admin' };
        setUser(userData);
        setIsAuthenticated(true);
        localStorage.setItem('motovids_admin', JSON.stringify(userData));
        return true;
      } else {
        setError('Invalid username or password');
        return false;
      }
    } catch (error) {
      setError('An error occurred during login');
      console.error('Login error:', error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  // Logout function
  const logout = () => {
    setUser(null);
    setIsAuthenticated(false);
    localStorage.removeItem('motovids_admin');
  };

  // Clear any errors
  const clearError = () => {
    setError(null);
  };

  // Context value
  const value = {
    isAuthenticated,
    user,
    error,
    loading,
    login,
    logout,
    clearError
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// Custom hook to use the auth context
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthContext; 