import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Box, Typography } from '@mui/material';
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { ChannelDetail, VideoDetail, SearchFeed, Navbar, Feed } from './components';
import Footer from "./components/Footer";
import AdSidebar from "./components/AdSidebar";
import SEO from "./components/SEO";
import GoogleAnalytics from "./components/GoogleAnalytics";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import NotFound from "./components/NotFound";
import ErrorBoundary from "./components/ErrorBoundary";
import Monitoring from "./components/Monitoring";
import OfflineNotice from "./components/OfflineNotice";
import DebugPanel from "./components/DebugPanel";
import { Suspense, lazy } from "react";
import { CircularProgress } from "@mui/material";
import TestApiKey from "./test-api-key";
import { AuthProvider } from "./utils/AuthContext";
import { LikesProvider } from "./utils/LikesContext";
import LikedVideos from "./components/LikedVideos";

// Lazy load components that aren't needed immediately
const LazyChannelDetail = lazy(() => import('./components/ChannelDetail'));
const LazyVideoDetail = lazy(() => import('./components/VideoDetail'));
const LazySearchFeed = lazy(() => import('./components/SearchFeed'));
const LazyPrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
const LazyTermsOfService = lazy(() => import('./components/TermsOfService'));
const LazyLikedVideos = lazy(() => import('./components/LikedVideos'));

// Loading fallback
const LoadingFallback = () => (
  <Box display="flex" justifyContent="center" alignItems="center" height="70vh">
    <CircularProgress color="error" />
  </Box>
);

const App = () => (
  <>
    <SEO />
    <AuthProvider>
      <LikesProvider>
        <BrowserRouter>
          <ErrorBoundary componentName="App">
            <Monitoring />
            <GoogleAnalytics />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: '#000',
                color: 'white'
              }}
            >
              <Navbar />
              <Routes>
                <Route exact path='/' element={
                  <ErrorBoundary componentName="HomePage">
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        px: { xs: 1, sm: 2, md: 4 },
                        mt: { xs: 2, sm: 3 }
                      }}
                    >
                      <Box sx={{ flex: 1 }}>
                        <Feed />
                      </Box>
                      <AdSidebar />
                    </Box>
                  </ErrorBoundary>
                } />
                <Route path='/test-api' element={<TestApiKey />} />
                <Route path='/video/:id' element={
                  <ErrorBoundary componentName="VideoDetail">
                    <Suspense fallback={<LoadingFallback />}>
                      <Box sx={{ 
                        display: 'flex',
                        flexDirection: 'column'
                      }}>
                        <Box sx={{ flex: 1 }}>
                          <LazyVideoDetail />
                        </Box>
                        <AdSidebar />
                      </Box>
                    </Suspense>
                  </ErrorBoundary>
                } />
                <Route path='/channel/:id' element={
                  <ErrorBoundary componentName="ChannelDetail">
                    <Suspense fallback={<LoadingFallback />}>
                      <Box sx={{ 
                        display: 'flex',
                        flexDirection: 'column'
                      }}>
                        <LazyChannelDetail />
                        <AdSidebar />
                      </Box>
                    </Suspense>
                  </ErrorBoundary>
                } />
                <Route path='/search/:searchTerm' element={
                  <ErrorBoundary componentName="SearchFeed">
                    <Suspense fallback={<LoadingFallback />}>
                      <Box sx={{ 
                        display: 'flex',
                        flexDirection: 'column'
                      }}>
                        <Box sx={{ flex: 1 }}>
                          <LazySearchFeed />
                        </Box>
                        <AdSidebar />
                      </Box>
                    </Suspense>
                  </ErrorBoundary>
                } />
                <Route path='/liked-videos' element={
                  <ErrorBoundary componentName="LikedVideos">
                    <Suspense fallback={<LoadingFallback />}>
                      <Box sx={{ 
                        display: 'flex',
                        flexDirection: 'column'
                      }}>
                        <Box sx={{ flex: 1 }}>
                          <LazyLikedVideos />
                        </Box>
                        <AdSidebar />
                      </Box>
                    </Suspense>
                  </ErrorBoundary>
                } />
                <Route path='/privacy' element={
                  <ErrorBoundary componentName="PrivacyPolicy">
                    <Suspense fallback={<LoadingFallback />}>
                      <LazyPrivacyPolicy />
                    </Suspense>
                  </ErrorBoundary>
                } />
                <Route path='/terms' element={
                  <ErrorBoundary componentName="TermsOfService">
                    <Suspense fallback={<LoadingFallback />}>
                      <LazyTermsOfService />
                    </Suspense>
                  </ErrorBoundary>
                } />
                
                {/* 404 route - must be last */}
                <Route path='*' element={<NotFound />} />
              </Routes>
              <Footer />
              <OfflineNotice />
              <DebugPanel />
            </Box>
          </ErrorBoundary>
        </BrowserRouter>
      </LikesProvider>
    </AuthProvider>
    {/* Analytics Integration */}
    <Analytics />
    <SpeedInsights />
  </>
);

export default App;