import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, CircularProgress, Button, Divider, Alert, LinearProgress } from '@mui/material';
import { checkRapidApiSubscription, getApiStatus, API_STATUS } from './utils/fetchFromAPI';
import { useAuth } from './utils/AuthContext';
import { Navigate } from 'react-router-dom';

const TestApiKey = () => {
  const [apiKeyStatus, setApiKeyStatus] = useState({ loading: true, result: null });
  const [apiStatus, setApiStatus] = useState(getApiStatus());
  const [rateLimitInfo, setRateLimitInfo] = useState(null);
  const { isAuthenticated } = useAuth();

  // Redirect to home if not authenticated
  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  useEffect(() => {
    console.log('Testing environment variables:');
    console.log('VITE_RAPID_API_KEY:', import.meta.env.VITE_RAPID_API_KEY);
    console.log('VITE_RAPID_API_HOST:', import.meta.env.VITE_RAPID_API_HOST);
    
    // Check API subscription status
    const checkSubscription = async () => {
      try {
        const result = await checkRapidApiSubscription();
        setApiKeyStatus({ loading: false, result });
        
        // Store rate limit info if available
        if (result.rateLimitInfo) {
          setRateLimitInfo(result.rateLimitInfo);
        }
      } catch (error) {
        setApiKeyStatus({ 
          loading: false, 
          result: { 
            valid: false, 
            error: error.message 
          } 
        });
      }
    };
    
    checkSubscription();
    
    // Listen for API status changes
    const handleApiStatusChange = (event) => {
      setApiStatus(event.detail.status);
    };
    
    // Listen for rate limit info updates
    const handleRateLimitInfo = (event) => {
      setRateLimitInfo({
        limit: event.detail.limit,
        remaining: event.detail.remaining,
        reset: event.detail.reset,
        usagePercentage: Number.parseInt(event.detail.remaining, 10) / Number.parseInt(event.detail.limit, 10) * 100
      });
    };
    
    window.addEventListener('api-status-changed', handleApiStatusChange);
    window.addEventListener('api-rate-limit-info', handleRateLimitInfo);
    
    return () => {
      window.removeEventListener('api-status-changed', handleApiStatusChange);
      window.removeEventListener('api-rate-limit-info', handleRateLimitInfo);
    };
  }, []);

  const handleRetryCheck = async () => {
    setApiKeyStatus({ loading: true, result: null });
    try {
      const result = await checkRapidApiSubscription();
      setApiKeyStatus({ loading: false, result });
      
      // Store rate limit info if available
      if (result.rateLimitInfo) {
        setRateLimitInfo(result.rateLimitInfo);
      }
    } catch (error) {
      setApiKeyStatus({ 
        loading: false, 
        result: { 
          valid: false, 
          error: error.message 
        } 
      });
    }
  };

  return (
    <Box sx={{ p: 4, maxWidth: '800px', mx: 'auto' }}>
      <Typography variant="h4" gutterBottom>RapidAPI Key Test</Typography>
      
      <Paper sx={{ p: 3, mb: 3, bgcolor: '#1e1e1e', color: 'white' }}>
        <Typography variant="h6" gutterBottom>Environment Variables</Typography>
        <Typography variant="body1">
          VITE_RAPID_API_KEY: {import.meta.env.VITE_RAPID_API_KEY ? 
            `${import.meta.env.VITE_RAPID_API_KEY.substring(0, 5)}...` : 
            'Not set'}
        </Typography>
        <Typography variant="body1">
          VITE_RAPID_API_HOST: {import.meta.env.VITE_RAPID_API_HOST || 'Not set'}
        </Typography>
      </Paper>
      
      <Paper sx={{ p: 3, mb: 3, bgcolor: '#1e1e1e', color: 'white' }}>
        <Typography variant="h6" gutterBottom>API Subscription Status</Typography>
        
        {apiKeyStatus.loading ? (
          <Box display="flex" alignItems="center">
            <CircularProgress size={20} sx={{ mr: 2 }} />
            <Typography>Checking API subscription status...</Typography>
          </Box>
        ) : apiKeyStatus.result?.valid ? (
          <Box sx={{ color: 'green' }}>
            <Typography variant="body1">✅ API subscription is valid</Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              Current API Status: {apiStatus === API_STATUS.ONLINE ? 
                '🟢 Online' : 
                apiStatus === API_STATUS.OFFLINE ? 
                  '🔴 Offline' : 
                  '🟡 Loading'}
            </Typography>
          </Box>
        ) : (
          <Box sx={{ color: 'red' }}>
            <Typography variant="body1">❌ API subscription check failed</Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>Error: {apiKeyStatus.result?.error}</Typography>
            
            {apiKeyStatus.result?.status && (
              <Typography variant="body2">Status Code: {apiKeyStatus.result.status}</Typography>
            )}
            
            {apiKeyStatus.result?.quotaExceeded && (
              <Alert severity="warning" sx={{ mt: 2, color: 'black' }}>
                Your API quota has been exceeded. Please wait for it to reset or upgrade your plan.
              </Alert>
            )}
            
            {apiKeyStatus.result?.data && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2">Response Data:</Typography>
                <pre style={{ overflow: 'auto', maxHeight: '200px' }}>
                  {JSON.stringify(apiKeyStatus.result.data, null, 2)}
                </pre>
              </Box>
            )}
          </Box>
        )}
        
        <Button 
          variant="contained" 
          color="primary" 
          sx={{ mt: 2 }}
          onClick={handleRetryCheck}
        >
          Retry Check
        </Button>
      </Paper>
      
      {rateLimitInfo && (
        <Paper sx={{ p: 3, bgcolor: '#1e1e1e', color: 'white' }}>
          <Typography variant="h6" gutterBottom>API Rate Limits</Typography>
          
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Limit:</span>
              <span>{rateLimitInfo.limit}</span>
            </Typography>
            <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Remaining:</span>
              <span>{rateLimitInfo.remaining}</span>
            </Typography>
            <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Reset:</span>
              <span>{rateLimitInfo.reset}</span>
            </Typography>
          </Box>
          
          <Box sx={{ mb: 1 }}>
            <Typography variant="body2" sx={{ mb: 1 }}>
              Usage: {Math.round(100 - rateLimitInfo.usagePercentage)}%
            </Typography>
            <LinearProgress 
              variant="determinate" 
              value={100 - rateLimitInfo.usagePercentage} 
              color={rateLimitInfo.usagePercentage < 20 ? "error" : rateLimitInfo.usagePercentage < 50 ? "warning" : "success"}
              sx={{ height: 10, borderRadius: 5 }}
            />
          </Box>
          
          {rateLimitInfo.usagePercentage < 10 && (
            <Alert severity="error" sx={{ mt: 2, color: 'black' }}>
              You are close to your API rate limit! Only {rateLimitInfo.remaining} requests remaining.
            </Alert>
          )}
          
          {rateLimitInfo.usagePercentage >= 10 && rateLimitInfo.usagePercentage < 30 && (
            <Alert severity="warning" sx={{ mt: 2, color: 'black' }}>
              You are approaching your API rate limit. {rateLimitInfo.remaining} requests remaining.
            </Alert>
          )}
        </Paper>
      )}
    </Box>
  );
};

export default TestApiKey; 