import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Container, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import ConstructionIcon from '@mui/icons-material/Construction';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';

const NotFound = () => {
  const [position, setPosition] = useState(0);
  const [shake, setShake] = useState(false);
  
  // Motorcycle movement animation
  useEffect(() => {
    const interval = setInterval(() => {
      setPosition(prev => {
        if (prev < 30) {
          return prev + 1;
        } else {
          clearInterval(interval);
          setShake(true);
          setTimeout(() => setShake(false), 500);
          return 30;
        }
      });
    }, 50);
    
    return () => clearInterval(interval);
  }, []);
  
  // Categories for quick navigation
  const categories = [
    'Supercross', 
    'Motocross', 
    'Freestyle', 
    'Hard Enduro', 
    'Racing', 
    'Stunts'
  ];

  return (
    <Box sx={{ 
      backgroundColor: '#0f0f0f', 
      color: '#fff', 
      minHeight: '90vh',
      pt: 8,
      pb: 10,
      position: 'relative',
      overflow: 'hidden'
    }}>
      {/* Background diagonal lines */}
      <Box sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 0.05,
        background: 'repeating-linear-gradient(45deg, #FC1503, #FC1503 10px, transparent 10px, transparent 20px)',
        zIndex: 0
      }} />
      
      <Container maxWidth="md" sx={{ position: 'relative', zIndex: 1 }}>
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Typography 
            variant="h1" 
            component="h1" 
            sx={{ 
              fontSize: { xs: '6rem', md: '10rem' }, 
              fontWeight: 900,
              background: 'linear-gradient(90deg, #FC1503, #ff6b00)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              letterSpacing: '-5px',
              mb: 0,
              lineHeight: 1
            }}
          >
            404
          </Typography>
          
          <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center', overflow: 'hidden', height: 80 }}>
            <Box sx={{ 
              position: 'relative', 
              left: `${position}%`,
              transform: shake ? 'rotate(5deg)' : 'rotate(0deg)',
              transition: shake ? 'transform 0.1s ease-in-out' : 'transform 0.5s ease-out',
              display: 'flex',
              alignItems: 'center'
            }}>
              <TwoWheelerIcon sx={{ fontSize: 50, color: '#FC1503' }} />
              <Box sx={{ 
                width: 20, 
                height: 5, 
                backgroundColor: '#FC1503', 
                opacity: 0.7,
                position: 'relative',
                right: 10,
                bottom: -10
              }} />
            </Box>
          </Box>
          
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center', 
            gap: 1,
            mt: 0
          }}>
            <ConstructionIcon sx={{ color: '#FC1503' }} />
            <Typography variant="h5" component="h2" sx={{ fontWeight: 700 }}>
              TRAIL NOT FOUND
            </Typography>
            <ConstructionIcon sx={{ color: '#FC1503' }} />
          </Box>
          
          <Typography variant="body1" sx={{ mt: 2, color: 'gray', maxWidth: 600, mx: 'auto' }}>
            Looks like you've ridden off the track! The page you're looking for is either under maintenance
            or doesn't exist. Let's get you back on the main trail.
          </Typography>
          
          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', gap: 2, flexWrap: 'wrap' }}>
            <Button 
              variant="contained" 
              component={Link} 
              to="/" 
              startIcon={<HomeIcon />}
              sx={{ 
                bgcolor: '#FC1503', 
                '&:hover': { bgcolor: '#d41305' },
                fontWeight: 600,
                borderRadius: '50px',
                py: 1.2,
                px: 3
              }}
            >
              Back to Home Track
            </Button>
            
            <Button 
              variant="outlined" 
              component={Link} 
              to="/" 
              startIcon={<SearchIcon />}
              sx={{ 
                borderColor: '#FC1503', 
                color: '#FC1503',
                '&:hover': { 
                  borderColor: '#d41305',
                  bgcolor: 'rgba(252, 21, 3, 0.1)'
                },
                fontWeight: 600,
                borderRadius: '50px',
                py: 1.2,
                px: 3
              }}
            >
              Search for Videos
            </Button>
          </Box>
        </Box>
        
        <Box sx={{ mt: 6 }}>
          <Typography variant="h6" sx={{ mb: 3, textAlign: 'center', fontWeight: 600 }}>
            Popular Categories
          </Typography>
          
          <Grid container spacing={2} justifyContent="center">
            {categories.map((category, index) => (
              <Grid item key={index}>
                <Button
                  component={Link}
                  to="/"
                  variant="outlined"
                  sx={{
                    borderColor: 'rgba(255,255,255,0.2)',
                    color: 'white',
                    '&:hover': {
                      borderColor: '#FC1503',
                      color: '#FC1503',
                      bgcolor: 'rgba(252, 21, 3, 0.05)'
                    },
                    borderRadius: '50px',
                    textTransform: 'none',
                    px: 2
                  }}
                >
                  {category}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
        
        <Box sx={{ 
          mt: 8, 
          p: 3, 
          bgcolor: 'rgba(255,255,255,0.05)', 
          borderRadius: 2,
          textAlign: 'center'
        }}>
          <Typography variant="body2" color="gray">
            If you believe this is an error, please contact us at <Box component="span" sx={{ color: '#FC1503' }}>support@motovids.com</Box>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default NotFound; 