import { Stack, IconButton, Box, useTheme, useMediaQuery, Menu, MenuItem, Badge, Tooltip, SwipeableDrawer, List, ListItem, ListItemIcon, ListItemText, Typography, BottomNavigation, BottomNavigationAction, Paper, Slide, SpeedDial, SpeedDialAction, SpeedDialIcon, Button } from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import NotificationsIcon from '@mui/icons-material/Notifications';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LogoutIcon from '@mui/icons-material/Logout';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { SearchBar } from './';
import { useState, useEffect, useRef } from 'react';
import ApiStatusIndicator from './ApiStatusIndicator';
import AdminLoginModal from './AdminLoginModal';
import UploadVideoModal from './UploadVideoModal';
import ComingSoonModal from './ComingSoonModal';
import NotificationsPopup from './NotificationsPopup';
import { useAuth } from '../utils/AuthContext';

const Navbar = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const navigate = useNavigate();
	const location = useLocation();
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [showSearch, setShowSearch] = useState(false);
	const [value, setValue] = useState('/');
	const [prevScrollPos, setPrevScrollPos] = useState(0);
	const [visible, setVisible] = useState(true);
	const [speedDialOpen, setSpeedDialOpen] = useState(false);
	const [loginModalOpen, setLoginModalOpen] = useState(false);
	const [uploadModalOpen, setUploadModalOpen] = useState(false);
	const [notificationsOpen, setNotificationsOpen] = useState(false);
	const notificationsAnchorRef = useRef(null);
	const [comingSoonModal, setComingSoonModal] = useState({
		open: false,
		title: '',
		icon: null
	});
	const { isAuthenticated, user, logout } = useAuth();

	useEffect(() => {
		setValue(location.pathname);
	}, [location]);

	useEffect(() => {
		const handleScroll = () => {
			const currentScrollPos = window.pageYOffset;
			setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
			setPrevScrollPos(currentScrollPos);
		};

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, [prevScrollPos]);

	const quickActions = [
		{ icon: <PhotoCameraIcon />, name: 'Record', action: () => console.log('Record') },
		{ icon: <FileUploadIcon />, name: 'Upload', action: () => handleOpenUploadModal() },
	];

	const menuItems = [
		{ text: 'Home', icon: <HomeIcon />, path: '/' },
		{ 
			text: 'Trending', 
			icon: <WhatshotIcon />, 
			path: '/trending',
			comingSoon: true
		},
		{ 
			text: 'Subscriptions', 
			icon: <SubscriptionsIcon />, 
			path: '/subscriptions',
			comingSoon: true
		},
		{
			text: 'Liked Videos',
			icon: <ThumbUpIcon />,
			path: '/liked-videos'
		},
		{ 
			text: 'Library', 
			icon: <VideoLibraryIcon />, 
			path: '/library',
			comingSoon: true
		},
	];

	const handleNavigation = (path, item) => {
		if (item?.comingSoon) {
			setComingSoonModal({
				open: true,
				title: item.text,
				icon: item.icon.type
			});
			return;
		}
		
		navigate(path);
		setDrawerOpen(false);
		setValue(path);
	};

	const handleOpenLoginModal = () => {
		setLoginModalOpen(true);
	};

	const handleCloseLoginModal = () => {
		setLoginModalOpen(false);
	};

	const handleOpenUploadModal = () => {
		setUploadModalOpen(true);
	};

	const handleCloseUploadModal = () => {
		setUploadModalOpen(false);
	};

	const handleToggleNotifications = () => {
		setNotificationsOpen(prev => !prev);
	};

	const handleCloseNotifications = () => {
		setNotificationsOpen(false);
	};

	const handleCloseComingSoonModal = () => {
		setComingSoonModal({
			...comingSoonModal,
			open: false
		});
	};

	const handleLogout = () => {
		logout();
	};

	return (
		<>
			<Slide appear={false} direction="down" in={visible}>
				<Stack
					direction='row'
					alignItems='center'
					p={isMobile ? 1 : 2}
					sx={{
						position: 'fixed',
						width: '100%',
						background: 'rgba(0, 0, 0, 0.95)',
						backdropFilter: 'blur(10px)',
						top: 0,
						justifyContent: 'space-between',
						borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
						zIndex: 1000
					}}
				>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: { xs: 1, sm: 2 } }}>
						<IconButton
							onClick={() => setDrawerOpen(true)}
							sx={{ color: 'white' }}
						>
							<MenuIcon />
						</IconButton>
						<Link to='/' style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
							<Box sx={{ 
								display: 'flex', 
								alignItems: 'center', 
								gap: 1,
								transition: 'transform 0.2s',
								'&:hover': {
									transform: 'scale(1.05)'
								}
							}}>
								<Box 
									component="img" 
									src="/motovids-logo.png" 
									alt="MotoVids Logo" 
									sx={{ 
										width: { xs: '1.5rem', sm: '2rem' },
										height: { xs: '1.5rem', sm: '2rem' }
									}} 
								/>
								<Typography 
									variant="h6" 
									component="div" 
									sx={{ 
										fontWeight: 700, 
										color: 'white',
										display: { xs: 'none', sm: 'block' },
										fontFamily: "'Montserrat', sans-serif",
										letterSpacing: '0.5px',
										fontSize: { sm: '1.2rem', md: '1.5rem' }
									}}
								>
									Moto<Box component="span" sx={{ color: "#FC1503" }}>Vids</Box>
								</Typography>
							</Box>
						</Link>
					</Box>

					{(!isMobile || !showSearch) && (
						<Box sx={{ 
							flex: 1, 
							maxWidth: { xs: '200px', sm: '400px', md: '800px' }, 
							mx: { xs: 1, sm: 2 },
							display: { xs: showSearch ? 'none' : 'block', md: 'block' }
						}}>
							<SearchBar />
						</Box>
					)}

					<Box sx={{ display: 'flex', alignItems: 'center', gap: { xs: 1, sm: 2 } }}>
						{isMobile && (
							<IconButton
								onClick={() => setShowSearch(!showSearch)}
								sx={{
									color: 'white',
									'&:hover': { color: 'red' },
									padding: { xs: '4px', sm: '8px' }
								}}
							>
								<SearchIcon sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }} />
							</IconButton>
						)}
						<ApiStatusIndicator />
						<Tooltip title="Upload Video">
							<IconButton
								onClick={handleOpenUploadModal}
								sx={{
									color: 'white',
									'&:hover': { color: 'red' },
									padding: { xs: '4px', sm: '8px' }
								}}
							>
								<VideoCallIcon sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }} />
							</IconButton>
						</Tooltip>
						<Tooltip title="Notifications">
							<IconButton
								ref={notificationsAnchorRef}
								onClick={handleToggleNotifications}
								sx={{
									color: 'white',
									'&:hover': { color: 'red' },
									padding: { xs: '4px', sm: '8px' }
								}}
							>
								<Badge 
									badgeContent={3} 
									color="error"
									sx={{
										'& .MuiBadge-badge': {
											fontSize: { xs: '0.6rem', sm: '0.75rem' }
										}
									}}
								>
									<NotificationsIcon sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }} />
								</Badge>
							</IconButton>
						</Tooltip>
						
						{isAuthenticated ? (
							<Tooltip title="Logout">
								<IconButton
									onClick={handleLogout}
									sx={{
										color: 'white',
										'&:hover': { color: 'red' },
										padding: { xs: '4px', sm: '8px' }
									}}
								>
									<LogoutIcon sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }} />
								</IconButton>
							</Tooltip>
						) : (
							<Tooltip title="Admin Login">
								<IconButton
									onClick={handleOpenLoginModal}
									sx={{
										color: 'white',
										'&:hover': { color: 'red' },
										padding: { xs: '4px', sm: '8px' }
									}}
								>
									<AdminPanelSettingsIcon sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }} />
								</IconButton>
							</Tooltip>
						)}
						
						{isAuthenticated && !isMobile && (
							<Box sx={{ ml: 1 }}>
								<Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
									Admin: {user?.username}
								</Typography>
							</Box>
						)}
					</Box>
				</Stack>
			</Slide>

			<Box sx={{ height: isMobile ? '56px' : '72px' }} />

			{showSearch && isMobile && (
				<Box sx={{ 
					p: 2, 
					backgroundColor: 'rgba(0, 0, 0, 0.95)',
					borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
					position: 'fixed',
					top: isMobile ? '56px' : '72px',
					left: 0,
					right: 0,
					zIndex: 999,
					display: 'flex',
					gap: 1,
					alignItems: 'center'
				}}>
					<SearchBar />
					<IconButton 
						size="small"
						onClick={() => setShowSearch(false)}
						sx={{ color: 'white' }}
					>
						<CloseIcon />
					</IconButton>
				</Box>
			)}

			<SwipeableDrawer
				anchor="left"
				open={drawerOpen}
				onClose={() => setDrawerOpen(false)}
				onOpen={() => setDrawerOpen(true)}
				PaperProps={{
					sx: {
						backgroundColor: 'rgba(30, 30, 30, 0.98)',
						backdropFilter: 'blur(10px)',
						width: { xs: '80%', sm: '300px' }
					}
				}}
				SwipeAreaProps={{
					sx: {
						width: { xs: 20, sm: 30 },
						left: 0
					}
				}}
			>
				<Box sx={{ p: 2 }}>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 3 }}>
						<Box component="img" src="/motovids-logo.png" alt="MotoVids Logo" sx={{ width: '1.8rem', height: '1.8rem' }} />
						<Typography variant="h6" color="white" fontWeight={700} fontFamily="'Montserrat', sans-serif">
							Moto<Box component="span" sx={{ color: "#FC1503" }}>Vids</Box>
						</Typography>
					</Box>
					<List>
						{menuItems.map((item) => (
							<ListItem 
								button 
								key={item.text}
								onClick={() => handleNavigation(item.path, item)}
								sx={{
									borderRadius: '8px',
									mb: 1,
									backgroundColor: location.pathname === item.path ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
									'&:hover': {
										backgroundColor: 'rgba(255, 255, 255, 0.1)'
									}
								}}
							>
								<ListItemIcon sx={{ color: location.pathname === item.path ? 'red' : 'white', minWidth: '40px' }}>
									{item.icon}
								</ListItemIcon>
								<ListItemText 
									primary={item.text}
									sx={{ 
										color: 'white',
										'& .MuiTypography-root': {
											fontWeight: location.pathname === item.path ? 500 : 400
										}
									}}
								/>
							</ListItem>
						))}
						
						{isAuthenticated && (
							<ListItem 
								button 
								onClick={() => {
									handleNavigation('/test-api');
									setDrawerOpen(false);
								}}
								sx={{
									borderRadius: '8px',
									mb: 1,
									backgroundColor: location.pathname === '/test-api' ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
									'&:hover': {
										backgroundColor: 'rgba(255, 255, 255, 0.1)'
									}
								}}
							>
								<ListItemIcon sx={{ color: location.pathname === '/test-api' ? 'red' : 'white', minWidth: '40px' }}>
									<AdminPanelSettingsIcon />
								</ListItemIcon>
								<ListItemText 
									primary="API Dashboard"
									sx={{ 
										color: 'white',
										'& .MuiTypography-root': {
											fontWeight: location.pathname === '/test-api' ? 500 : 400
										}
									}}
								/>
							</ListItem>
						)}
					</List>
				</Box>
			</SwipeableDrawer>

			{isMobile && (
				<>
					<Paper 
						sx={{ 
							position: 'fixed', 
							bottom: 0, 
							left: 0, 
							right: 0,
							pb: 1,
							zIndex: 1001,
							backgroundColor: 'rgba(0, 0, 0, 0.95)',
							backdropFilter: 'blur(10px)',
							borderTop: '1px solid rgba(255, 255, 255, 0.1)',
							transition: 'transform 0.3s ease-in-out',
							transform: visible ? 'translateY(0)' : 'translateY(100%)'
						}} 
						elevation={3}
					>
						<BottomNavigation
							value={value}
							onChange={(event, newValue) => {
								const selectedItem = menuItems.find(item => item.path === newValue);
								if (selectedItem?.comingSoon) {
									setComingSoonModal({
										open: true,
										title: selectedItem.text,
										icon: selectedItem.icon.type
									});
									return;
								}
								setValue(newValue);
								navigate(newValue);
							}}
							sx={{
								backgroundColor: 'transparent',
								height: '60px'
							}}
						>
							{menuItems.map((item) => (
								<BottomNavigationAction
									key={item.path}
									value={item.path}
									icon={item.icon}
									sx={{
										color: 'rgba(255, 255, 255, 0.6)',
										'&.Mui-selected': {
											color: 'red'
										}
									}}
								/>
							))}
						</BottomNavigation>
					</Paper>

					<SpeedDial
						ariaLabel="Quick Actions"
						sx={{
							position: 'fixed',
							bottom: 80,
							right: 16,
							'& .MuiFab-primary': {
								bgcolor: 'red',
								'&:hover': {
									bgcolor: '#ff1111'
								}
							}
						}}
						icon={<SpeedDialIcon />}
						onClose={() => setSpeedDialOpen(false)}
						onOpen={() => setSpeedDialOpen(true)}
						open={speedDialOpen}
					>
						{quickActions.map((action) => (
							<SpeedDialAction
								key={action.name}
								icon={action.icon}
								tooltipTitle={action.name}
								onClick={action.action}
								sx={{
									bgcolor: 'rgba(30, 30, 30, 0.98)',
									color: 'white',
									'&:hover': {
										bgcolor: 'rgba(50, 50, 50, 0.98)'
									}
								}}
							/>
						))}
					</SpeedDial>
				</>
			)}
			
			<AdminLoginModal open={loginModalOpen} onClose={handleCloseLoginModal} />
			<UploadVideoModal open={uploadModalOpen} onClose={handleCloseUploadModal} />
			<ComingSoonModal 
				open={comingSoonModal.open} 
				onClose={handleCloseComingSoonModal} 
				title={comingSoonModal.title}
				icon={comingSoonModal.icon}
			/>
			<NotificationsPopup 
				open={notificationsOpen} 
				anchorEl={notificationsAnchorRef.current}
				onClose={handleCloseNotifications}
			/>
		</>
	);
};

export default Navbar;
