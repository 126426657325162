import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Skeleton, Card, CardMedia, CardContent, CardActions, Chip, Rating } from '@mui/material';
import { motion } from 'framer-motion';
import { trackEvent } from './GoogleAnalytics';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StarIcon from '@mui/icons-material/Star';

// Motorcycle product data with Amazon affiliate links
// Replace 'your-affiliate-tag' with your actual Amazon affiliate tag
const AFFILIATE_TAG = 'motovids-20'; // Example tag, replace with yours

// Updated product data with the provided Amazon affiliate links, titles and local images
const PRODUCTS = [
  {
    id: 'product1',
    title: 'Dirt Bike Motorcycle Gloves Motocross Gloves',
    description: 'Motorbike Riding Bike Gloves ATV MX MTB Off Road Racing Sports Cycling Glove',
    image: '/src/assets/product-images/product1.jpg',
    remoteImage: 'https://m.media-amazon.com/images/I/71cjG0fvjJL._AC_SL1500_.jpg',
    fallbackImage: 'https://m.media-amazon.com/images/I/71cjG0fvjJL._AC_SL1500_.jpg',
    price: '$24.99',
    rating: 4.7,
    reviews: 2845,
    link: 'https://amzn.to/4ixi2pi'
  },
  {
    id: 'product2',
    title: 'Skeleton-Paw Motorcycle Gloves',
    description: 'For Men & Women, Cycling Glove Breathable Dirtbike Glove for ATV MTB Motocross Racing',
    image: '/src/assets/product-images/product2.jpg',
    remoteImage: 'https://m.media-amazon.com/images/I/81221NaT+yL._AC_SX679_.jpg',
    fallbackImage: 'https://m.media-amazon.com/images/I/81221NaT+yL._AC_SX679_.jpg',
    price: '$19.99',
    rating: 4.8,
    reviews: 3256,
    link: 'https://amzn.to/4itbCqZ'
  },
  {
    id: 'product3',
    title: 'Alpinestars S-M5 Solid Helmet',
    description: 'Black Matte (2X) Premium Motorcycle Helmet with Advanced Protection',
    image: '/src/assets/product-images/product3.jpg',
    remoteImage: 'https://m.media-amazon.com/images/I/61qzAwIlNeL._AC_SX679_.jpg',
    fallbackImage: 'https://m.media-amazon.com/images/I/61qzAwIlNeL._AC_SX679_.jpg',
    price: '$249.99',
    rating: 4.9,
    reviews: 876,
    link: 'https://amzn.to/3DK7xA4'
  },
  {
    id: 'product4',
    title: 'Alpinestars Goanna Bag',
    description: 'Unisex-Adult Black/White Motorcycle Gear Bag (Multi, one_size)',
    image: '/src/assets/product-images/product4.jpg',
    remoteImage: 'https://m.media-amazon.com/images/I/91HKISnmLaL._AC_SX679_.jpg',
    fallbackImage: 'https://m.media-amazon.com/images/I/91HKISnmLaL._AC_SX679_.jpg',
    price: '$79.99',
    rating: 4.6,
    reviews: 1543,
    link: 'https://amzn.to/3R9PdmP'
  },
  {
    id: 'product5',
    title: 'Fox Racing Unisex Main Motocross Dirt Bike Goggle',
    description: 'Premium Motocross Goggles with Anti-Fog Lens and Tear-Off Posts',
    image: '/src/assets/product-images/product5.jpg',
    remoteImage: 'https://m.media-amazon.com/images/I/61RuS67vO0L._AC_SL1500_.jpg',
    fallbackImage: 'https://m.media-amazon.com/images/I/61RuS67vO0L._AC_SL1500_.jpg',
    price: '$39.99',
    rating: 4.4,
    reviews: 2156,
    link: 'https://amzn.to/4hb9MKf'
  }
];

// Default fallback image URL
const DEFAULT_FALLBACK_IMAGE = 'https://m.media-amazon.com/images/I/71cjG0fvjJL._AC_SL1500_.jpg';

const AffiliateAd = ({ position, layout = 'horizontal', size = 'medium' }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [useRemoteImage, setUseRemoteImage] = useState(false);
  
  // Get random product based on position
  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => {
      // Select a product based on position to ensure different ads in different positions
      const positionHash = position.charCodeAt(0) % PRODUCTS.length;
      const selectedProduct = PRODUCTS[(positionHash + Math.floor(Math.random() * 3)) % PRODUCTS.length];
      setProduct(selectedProduct);
      setIsLoading(false);
      setImageError(false); // Reset image error state when product changes
      setUseRemoteImage(false); // Start with local images
    }, 800);
    
    return () => clearTimeout(timer);
  }, [position]);
  
  // Track ad clicks
  const handleAdClick = () => {
    trackEvent('Affiliate', 'click', product?.title || 'unknown product');
  };
  
  // Handle image loading error
  const handleImageError = () => {
    console.log('Image failed to load, trying alternative source');
    if (!useRemoteImage) {
      // If local image fails, try remote image
      setUseRemoteImage(true);
    } else {
      // If remote image also fails, use fallback
      setImageError(true);
    }
  };
  
  // Get image source with fallback handling
  const getImageSrc = () => {
    if (!product) return DEFAULT_FALLBACK_IMAGE;
    if (imageError) return product.fallbackImage || DEFAULT_FALLBACK_IMAGE;
    if (useRemoteImage) return product.remoteImage;
    return product.image;
  };
  
  // Size configurations
  const sizes = {
    small: {
      height: layout === 'horizontal' ? '100px' : '280px',
      imageHeight: layout === 'horizontal' ? '80px' : '120px',
      fontSize: '0.8rem',
      buttonSize: 'small'
    },
    medium: {
      height: layout === 'horizontal' ? '150px' : '350px',
      imageHeight: layout === 'horizontal' ? '120px' : '160px',
      fontSize: '0.9rem',
      buttonSize: 'medium'
    },
    large: {
      height: layout === 'horizontal' ? '200px' : '420px',
      imageHeight: layout === 'horizontal' ? '160px' : '200px',
      fontSize: '1rem',
      buttonSize: 'large'
    }
  };
  
  const currentSize = sizes[size] || sizes.medium;
  
  // Render loading skeleton
  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        width="100%"
        height={currentSize.height}
        sx={{
          my: 2,
          bgcolor: 'rgba(255, 255, 255, 0.05)',
          borderRadius: '12px'
        }}
      />
    );
  }
  
  // Render horizontal layout
  if (layout === 'horizontal') {
    return (
      <Box
        component={motion.div}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        whileHover={{ 
          scale: 1.02,
          boxShadow: '0 8px 20px rgba(0, 0, 0, 0.3)'
        }}
        transition={{ duration: 0.3 }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleAdClick}
        sx={{
          width: '100%',
          height: currentSize.height,
          my: 2,
          borderRadius: '12px',
          overflow: 'hidden',
          position: 'relative',
          backgroundColor: 'rgba(30, 30, 30, 0.8)',
          display: 'flex',
          flexDirection: 'row',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          cursor: 'pointer',
          transition: 'transform 0.3s, box-shadow 0.3s',
          '&:hover': {
            borderColor: '#FF9900',
          }
        }}
      >
        <Box
          sx={{
            width: '30%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            p: 1,
            position: 'relative',
            overflow: 'hidden',
            backgroundColor: 'rgba(0, 0, 0, 0.2)'
          }}
        >
          <Box
            component={motion.img}
            animate={isHovered ? { scale: 1.1 } : { scale: 1 }}
            transition={{ duration: 0.3 }}
            src={getImageSrc()}
            alt=""
            aria-hidden="true"
            onError={handleImageError}
            sx={{
              height: currentSize.imageHeight,
              maxWidth: '100%',
              objectFit: 'contain',
              borderRadius: '8px',
              filter: 'drop-shadow(0 4px 6px rgba(0, 0, 0, 0.2))'
            }}
          />
        </Box>
        
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            p: 2
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              color: 'white',
              fontWeight: 'bold',
              mb: 0.5,
              fontSize: currentSize.fontSize,
              lineHeight: 1.2,
              letterSpacing: '0.01em',
              textShadow: '0 1px 2px rgba(0,0,0,0.3)'
            }}
          >
            {product?.title}
          </Typography>
          
          {size !== 'small' && (
            <Typography
              variant="body2"
              sx={{
                color: 'rgba(255, 255, 255, 0.8)',
                mb: 1,
                fontSize: '0.85rem',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                fontWeight: 300,
                letterSpacing: '0.02em'
              }}
            >
              {product?.description}
            </Typography>
          )}
          
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, gap: 1 }}>
            <Rating 
              value={product?.rating || 0} 
              precision={0.1} 
              size="small" 
              readOnly 
              emptyIcon={<StarIcon style={{ opacity: 0.3, color: 'white' }} fontSize="inherit" />}
            />
            <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
              ({product?.reviews})
            </Typography>
          </Box>
          
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
            <Typography
              variant="body1"
              sx={{
                color: '#4caf50',
                fontWeight: 'bold',
                fontSize: '1.1rem',
                textShadow: '0 1px 1px rgba(0,0,0,0.2)'
              }}
            >
              {product?.price}
            </Typography>
            
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <LocalShippingIcon sx={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: '0.9rem', mr: 0.5 }} />
              <Typography 
                variant="caption" 
                sx={{ 
                  color: 'rgba(255, 255, 255, 0.7)',
                  fontWeight: 500
                }}
              >
                Free Shipping
              </Typography>
            </Box>
          </Box>
          
          <Button
            component={motion.a}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            variant="contained"
            color="primary"
            size={currentSize.buttonSize}
            href={product?.link}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => {
              e.stopPropagation();
              handleAdClick();
            }}
            startIcon={<ShoppingCartIcon />}
            sx={{
              alignSelf: 'flex-start',
              backgroundColor: '#FF9900',
              '&:hover': {
                backgroundColor: '#e68a00'
              },
              fontWeight: 'bold',
              borderRadius: '20px',
              px: 2,
              boxShadow: '0 2px 8px rgba(255, 153, 0, 0.3)'
            }}
          >
            View on Amazon
          </Button>
        </Box>
        
        <Typography
          variant="caption"
          sx={{
            position: 'absolute',
            top: 5,
            right: 10,
            color: 'rgba(255, 255, 255, 0.5)',
            fontSize: '0.7rem'
          }}
        >
          Sponsored
        </Typography>
      </Box>
    );
  }
  
  // Render vertical/card layout
  return (
    <Card
      component={motion.div}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ 
        scale: 1.03,
        boxShadow: '0 10px 25px rgba(0, 0, 0, 0.3)'
      }}
      transition={{ duration: 0.3 }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleAdClick}
      sx={{
        width: '100%',
        my: 2,
        borderRadius: '12px',
        overflow: 'hidden',
        backgroundColor: 'rgba(30, 30, 30, 0.8)',
        border: '1px solid rgba(255, 255, 255, 0.1)',
        position: 'relative',
        cursor: 'pointer',
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
          borderColor: '#FF9900',
        }
      }}
    >
      <Typography
        variant="caption"
        sx={{
          position: 'absolute',
          top: 5,
          right: 10,
          color: 'rgba(255, 255, 255, 0.5)',
          fontSize: '0.7rem',
          zIndex: 1
        }}
      >
        Sponsored
      </Typography>
      
      <Box sx={{ position: 'relative', overflow: 'hidden', backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        <CardMedia
          component={motion.img}
          animate={isHovered ? { scale: 1.1 } : { scale: 1 }}
          transition={{ duration: 0.3 }}
          height={currentSize.imageHeight}
          image={getImageSrc()}
          alt=""
          aria-hidden="true"
          onError={handleImageError}
          sx={{ 
            objectFit: 'contain', 
            p: 2, 
            filter: 'drop-shadow(0 4px 6px rgba(0, 0, 0, 0.2))'
          }}
        />
      </Box>
      
      <CardContent sx={{ pb: 1 }}>
        <Typography
          variant="subtitle1"
          sx={{
            color: 'white',
            fontWeight: 'bold',
            fontSize: currentSize.fontSize,
            lineHeight: 1.2,
            mb: 1,
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            height: '2.4em',
            letterSpacing: '0.01em',
            textShadow: '0 1px 2px rgba(0,0,0,0.3)'
          }}
        >
          {product?.title}
        </Typography>
        
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, gap: 1 }}>
          <Rating 
            value={product?.rating || 0} 
            precision={0.1} 
            size="small" 
            readOnly 
            emptyIcon={<StarIcon style={{ opacity: 0.3, color: 'white' }} fontSize="inherit" />}
          />
          <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
            ({product?.reviews})
          </Typography>
        </Box>
        
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
          <Typography
            variant="body1"
            sx={{
              color: '#4caf50',
              fontWeight: 'bold',
              fontSize: '1.1rem',
              textShadow: '0 1px 1px rgba(0,0,0,0.2)'
            }}
          >
            {product?.price}
          </Typography>
          
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <LocalShippingIcon sx={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: '0.9rem', mr: 0.5 }} />
            <Typography 
              variant="caption" 
              sx={{ 
                color: 'rgba(255, 255, 255, 0.7)',
                fontWeight: 500
              }}
            >
              Free Shipping
            </Typography>
          </Box>
        </Box>
      </CardContent>
      
      <CardActions sx={{ pt: 0, pb: 2, px: 2 }}>
        <Button
          component={motion.a}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          variant="contained"
          color="primary"
          size={currentSize.buttonSize}
          href={product?.link}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            e.stopPropagation();
            handleAdClick();
          }}
          startIcon={<ShoppingCartIcon />}
          fullWidth
          sx={{
            backgroundColor: '#FF9900',
            '&:hover': {
              backgroundColor: '#e68a00'
            },
            fontWeight: 'bold',
            borderRadius: '20px',
            py: 1,
            boxShadow: '0 2px 8px rgba(255, 153, 0, 0.3)'
          }}
        >
          View on Amazon
        </Button>
      </CardActions>
    </Card>
  );
};

export default AffiliateAd; 