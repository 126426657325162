import React, { useState } from 'react';
import { Box, Typography, useMediaQuery, useTheme, Divider, IconButton, Collapse } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AffiliateAd from './AffiliateAd';

const AdSidebar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [startY, setStartY] = useState(0);
  const [currentY, setCurrentY] = useState(0);

  const handleTouchStart = (e) => {
    setStartY(e.touches[0].clientY);
    setCurrentY(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    setCurrentY(e.touches[0].clientY);
    if (Math.abs(startY - e.touches[0].clientY) > 50) {
      setIsCollapsed(startY < e.touches[0].clientY);
    }
  };

  // Mobile version (fixed at bottom)
  if (isMobile) {
    return (
      <AnimatePresence>
        <Box
          component={motion.div}
          initial={{ opacity: 0, y: 50 }}
          animate={{ 
            opacity: 1, 
            y: 0,
            height: isCollapsed ? '40px' : '100px'
          }}
          transition={{ duration: 0.3 }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          sx={{
            width: '100%',
            position: 'fixed',
            bottom: '60px',
            left: 0,
            zIndex: 1000,
            backgroundColor: 'rgba(30, 30, 30, 0.98)',
            backdropFilter: 'blur(10px)',
            borderTop: '1px solid rgba(255, 255, 255, 0.1)',
            display: { xs: 'block', sm: 'none' },
            touchAction: 'none'
          }}
        >
          <IconButton
            onClick={() => setIsCollapsed(!isCollapsed)}
            sx={{
              position: 'absolute',
              top: 0,
              left: '50%',
              transform: 'translateX(-50%)',
              color: 'white',
              zIndex: 2
            }}
          >
            {isCollapsed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          
          <Collapse in={!isCollapsed} timeout={300}>
            <Box sx={{ position: 'relative', mt: 4 }}>
              <AffiliateAd position="mobile_bottom" layout="horizontal" size="small" />
            </Box>
          </Collapse>
        </Box>
      </AnimatePresence>
    );
  }

  // Tablet version (horizontal layout)
  if (isTablet) {
    return (
      <Box
        component={motion.div}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        sx={{
          width: '100%',
          backgroundColor: 'rgba(30, 30, 30, 0.95)',
          backdropFilter: 'blur(10px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          p: 3,
          my: 4,
          borderRadius: '16px',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography 
          variant="subtitle2" 
          color="gray" 
          gutterBottom
          sx={{
            textTransform: 'uppercase',
            letterSpacing: '1px',
            fontSize: '0.75rem',
            mb: 2
          }}
        >
          Sponsored Content
        </Typography>
        
        <Divider 
          sx={{ 
            width: '100%', 
            mb: 3,
            backgroundColor: 'rgba(255, 255, 255, 0.1)' 
          }} 
        />
        
        <Box sx={{ width: '100%' }}>
          <AffiliateAd position="tablet_bottom" layout="horizontal" size="medium" />
        </Box>
      </Box>
    );
  }

  // Desktop version (horizontal layout at bottom)
  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      sx={{
        width: '100%',
        backgroundColor: 'rgba(30, 30, 30, 0.95)',
        backdropFilter: 'blur(10px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        p: 4,
        my: 4,
        borderRadius: '16px',
        border: '1px solid rgba(255, 255, 255, 0.1)',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Typography 
        variant="subtitle2" 
        color="gray" 
        gutterBottom
        sx={{
          textTransform: 'uppercase',
          letterSpacing: '1px',
          fontSize: '0.75rem',
          mb: 2
        }}
      >
        Sponsored Content
      </Typography>
      
      <Divider 
        sx={{ 
          width: '100%', 
          mb: 3,
          backgroundColor: 'rgba(255, 255, 255, 0.1)' 
        }} 
      />
      
      <Box sx={{ 
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 3
      }}>
        {/* First ad */}
        <Box sx={{ flex: 1 }}>
          <AffiliateAd position="bottom_left" layout="horizontal" size="medium" />
        </Box>
        
        {/* Second ad */}
        <Box sx={{ flex: 1 }}>
          <AffiliateAd position="bottom_right" layout="horizontal" size="medium" />
        </Box>
      </Box>
    </Box>
  );
};

export default AdSidebar; 