import React from 'react';
import { 
  Popover, 
  List, 
  ListItem, 
  ListItemText, 
  ListItemAvatar, 
  Avatar, 
  Typography, 
  Box, 
  Divider,
  IconButton,
  Badge
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';

const notifications = [
  {
    id: 1,
    title: 'New Yamaha R1 video added',
    time: '2 hours ago',
    read: false,
    avatar: <DirectionsBikeIcon />
  },
  {
    id: 2,
    title: 'MotoGP highlights now available',
    time: '1 day ago',
    read: false,
    avatar: <DirectionsBikeIcon />
  },
  {
    id: 3,
    title: 'New motorcycle maintenance tutorial',
    time: '3 days ago',
    read: true,
    avatar: <DirectionsBikeIcon />
  }
];

const NotificationsPopup = ({ anchorEl, open, onClose }) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        sx: {
          width: { xs: '300px', sm: '350px' },
          bgcolor: '#1e1e1e',
          color: 'white',
          borderRadius: 2,
          boxShadow: '0 4px 20px rgba(0,0,0,0.5)',
          overflow: 'hidden',
          mt: 1
        }
      }}
    >
      <Box sx={{ 
        p: 2, 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <NotificationsIcon sx={{ color: '#FC1503' }} />
          <Typography variant="h6">Notifications</Typography>
        </Box>
        <Badge 
          badgeContent={notifications.filter(n => !n.read).length} 
          color="error"
          sx={{ '& .MuiBadge-badge': { fontSize: '0.7rem' } }}
        >
          <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">
            New
          </Typography>
        </Badge>
      </Box>
      
      {notifications.length > 0 ? (
        <List sx={{ p: 0 }}>
          {notifications.map((notification, index) => (
            <React.Fragment key={notification.id}>
              <ListItem 
                alignItems="flex-start"
                sx={{ 
                  py: 2,
                  px: 2,
                  bgcolor: notification.read ? 'transparent' : 'rgba(252, 21, 3, 0.05)',
                  '&:hover': {
                    bgcolor: 'rgba(255, 255, 255, 0.05)'
                  }
                }}
              >
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: notification.read ? 'rgba(255, 255, 255, 0.1)' : '#FC1503' }}>
                    {notification.avatar}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Typography 
                        variant="body1" 
                        component="span" 
                        sx={{ 
                          fontWeight: notification.read ? 400 : 600,
                          color: 'white'
                        }}
                      >
                        {notification.title}
                      </Typography>
                      {!notification.read && (
                        <Box 
                          component="span" 
                          sx={{ 
                            width: 8, 
                            height: 8, 
                            borderRadius: '50%', 
                            bgcolor: '#FC1503',
                            display: 'inline-block'
                          }}
                        />
                      )}
                    </Box>
                  }
                  secondary={
                    <Typography 
                      variant="caption" 
                      component="span" 
                      sx={{ color: 'rgba(255, 255, 255, 0.6)' }}
                    >
                      {notification.time}
                    </Typography>
                  }
                />
              </ListItem>
              {index < notifications.length - 1 && (
                <Divider variant="inset" component="li" sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} />
              )}
            </React.Fragment>
          ))}
        </List>
      ) : (
        <Box sx={{ p: 4, textAlign: 'center' }}>
          <CheckCircleIcon sx={{ fontSize: 48, color: 'rgba(255, 255, 255, 0.3)', mb: 2 }} />
          <Typography variant="body1" color="rgba(255, 255, 255, 0.7)">
            No new notifications
          </Typography>
        </Box>
      )}
      
      <Box sx={{ 
        p: 2, 
        borderTop: '1px solid rgba(255, 255, 255, 0.1)',
        textAlign: 'center'
      }}>
        <Typography 
          variant="body2" 
          sx={{ 
            color: '#FC1503',
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline'
            }
          }}
          onClick={onClose}
        >
          Mark all as read
        </Typography>
      </Box>
    </Popover>
  );
};

export default NotificationsPopup; 