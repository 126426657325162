import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Paper, IconButton, InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const onhandleSubmit = (e) => {
    e.preventDefault();

    if (searchTerm) {
      navigate(`/search/${searchTerm}`);

      setSearchTerm('');
    }
  };

  return (
    <Paper
      component='form'
      onSubmit={onhandleSubmit}
      sx={{
        borderRadius: 20,
        border: '1px solid #e3e3e3',
        boxShadow: 'none',
        mr: { sm: 5 },
        display: 'flex',
        alignItems: 'center',
        height: '40px',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        '&:hover': {
          backgroundColor: 'white',
        }
      }}
    >
      <InputBase
        sx={{ 
          ml: 2, 
          flex: 1,
          '& .MuiInputBase-input': {
            padding: '8px 0',
          }
        }}
        placeholder='Search...'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        inputProps={{ 'aria-label': 'search videos' }}
      />
      <IconButton 
        type='submit' 
        sx={{ 
          p: '8px', 
          color: 'red',
          borderRadius: '0 20px 20px 0',
          '&:hover': {
            backgroundColor: 'rgba(255, 0, 0, 0.1)',
          }
        }} 
        aria-label='search'
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

export default SearchBar;