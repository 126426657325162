import MusicNoteIcon from '@mui/icons-material/MusicNote';
import HomeIcon from '@mui/icons-material/Home';
import CodeIcon from '@mui/icons-material/Code';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import SchoolIcon from '@mui/icons-material/School';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';


import TwoWheelerTwoToneIcon from '@mui/icons-material/TwoWheelerTwoTone';
import AccessibleForwardTwoToneIcon from '@mui/icons-material/AccessibleForwardTwoTone';
import SportsMotorsportsTwoToneIcon from '@mui/icons-material/SportsMotorsportsTwoTone';
import GrassIcon from '@mui/icons-material/Grass';
import AirIcon from '@mui/icons-material/Air';
import ConstructionIcon from '@mui/icons-material/Construction';
import SpeedIcon from '@mui/icons-material/Speed';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import OndemandVideoTwoToneIcon from '@mui/icons-material/OndemandVideoTwoTone';

export const categories = [
	{ name: 'Supercross', icon: <SportsMotorsportsTwoToneIcon /> },
	{ name: 'Motocross', icon: <TwoWheelerTwoToneIcon /> },
	{ name: 'AMA Supercross', icon: <EmojiEventsIcon /> },
	{ name: 'MXGP', icon: <DirectionsBikeIcon /> },
	{ name: 'Moto Freestyle', icon: <AirIcon /> },
	{ name: 'Moto Freeride', icon: <GrassIcon /> },
	{ name: 'Hard Enduro', icon: <AccessibleForwardTwoToneIcon /> },
	{ name: 'Trials', icon: <DirectionsBikeIcon /> },
	{ name: 'Motobike Fails', icon: <AccessibleForwardTwoToneIcon /> },
	{ name: 'Motorcycle Stunts', icon: <AirIcon /> },
	{ name: 'Motorcycle Maintenance', icon: <ConstructionIcon /> },
	{ name: 'Motorcycle Reviews', icon: <SpeedIcon /> },
	{ name: 'Motorcycle Racing', icon: <SportsMotorsportsTwoToneIcon /> },
	{ name: 'Motorcycle Tutorials', icon: <OndemandVideoTwoToneIcon /> },
];

export const demoThumbnailUrl = 'https://i.ibb.co/G2L2Gwp/API-Course.png';
export const demoChannelUrl = '/channel/UCmXmlB4-HJytD7wek0Uo97A';
export const demoVideoUrl = '/video/GDa8kZLNhJ4';
export const demoChannelTitle = 'JavaScript Mastery';
export const demoVideoTitle = 'Build and Deploy 5 JavaScript & React API Projects in 10 Hours - Full Course | RapidAPI';
export const demoProfilePicture = 'http://dergipark.org.tr/assets/app/images/buddy_sample.png';
