import axios from 'axios';
import { logEvent, trackApiPerformance } from './analytics';

const BASE_URL = 'https://youtube138.p.rapidapi.com';

// Cache for API responses
const apiCache = new Map();
const CACHE_TTL = 24 * 60 * 60 * 1000; // Increase to 24 hours to reduce API calls

// User's country code (default to global)
let userCountry = 'global';

// Try to detect user's country
try {
  // Use browser's language settings as a fallback
  const browserLang = navigator?.language || navigator?.userLanguage;
  if (browserLang?.includes('-')) {
    userCountry = browserLang.split('-')[1].toLowerCase();
  }
  
  // Try to get more accurate location using IP-based geolocation
  fetch('https://ipapi.co/json/')
    .then(response => response.json())
    .then(data => {
      if (data?.country_code) {
        userCountry = data.country_code.toLowerCase();
        console.log(`Detected user country: ${userCountry}`);
        // Dispatch event to notify components
        window.dispatchEvent(new CustomEvent('country-detected', { 
          detail: { country: userCountry } 
        }));
      }
    })
    .catch(err => {
      console.warn('Could not detect country from IP:', err);
    });
} catch (e) {
  console.warn('Error detecting user country:', e);
}

// Get user's country code
export const getUserCountry = () => userCountry;

// Data source status tracking
export const API_STATUS = {
  ONLINE: 'online',
  OFFLINE: 'offline',
  LOADING: 'loading'
};

// Create a custom event for API status changes
export const dataSourceStatusChanged = (status) => {
  const event = new CustomEvent('api-status-changed', { detail: { status } });
  window.dispatchEvent(event);
  console.log(`API Status changed to: ${status}`);
  return status;
};

// Current API status
let currentApiStatus = API_STATUS.LOADING;

// Get current API status
export const getApiStatus = () => currentApiStatus;

// Function to check RapidAPI subscription status
export const checkRapidApiSubscription = async () => {
  try {
    const apiKey = import.meta.env.VITE_RAPID_API_KEY;
    if (!apiKey) {
      console.error("API Key is missing! Make sure VITE_RAPID_API_KEY is set in your environment variables.");
      return { valid: false, error: "API Key is missing" };
    }
    
    // Make a simple request to check if the API key is valid
    const options = {
      method: 'GET',
      url: `${BASE_URL}/search/`,
      params: { 
        q: 'test', 
        hl: 'en', 
        gl: userCountry 
      },
      headers: {
        'X-RapidAPI-Key': apiKey,
        'X-RapidAPI-Host': 'youtube138.p.rapidapi.com'
      },
      timeout: 10000
    };
    
    const response = await axios.request(options);
    
    // Check for rate limit headers
    const requestsLimit = response.headers['x-ratelimit-requests-limit'];
    const requestsRemaining = response.headers['x-ratelimit-requests-remaining'];
    const requestsReset = response.headers['x-ratelimit-requests-reset'];
    
    let rateLimitInfo = {};
    if (requestsLimit && requestsRemaining) {
      rateLimitInfo = {
        limit: requestsLimit,
        remaining: requestsRemaining,
        reset: requestsReset || 'N/A',
        usagePercentage: Number.parseInt(requestsRemaining, 10) / Number.parseInt(requestsLimit, 10) * 100
      };
      
      console.log(`API Rate Limits - Limit: ${requestsLimit}, Remaining: ${requestsRemaining}, Reset: ${requestsReset || 'N/A'}`);
    }
    
    if (response.status === 200) {
      console.log("RapidAPI subscription is valid");
      currentApiStatus = dataSourceStatusChanged(API_STATUS.ONLINE);
      return { 
        valid: true,
        rateLimitInfo
      };
    }
    
    console.error("Unexpected response from RapidAPI:", response.status);
    currentApiStatus = dataSourceStatusChanged(API_STATUS.OFFLINE);
    return { 
      valid: false, 
      error: `Unexpected response: ${response.status}`,
      rateLimitInfo
    };
  } catch (error) {
    console.error("Error checking RapidAPI subscription:", error.message);
    if (error.response) {
      console.error("Response status:", error.response.status);
      console.error("Response data:", error.response.data);
      
      // Check for rate limit headers in error response
      const requestsLimit = error.response.headers?.['x-ratelimit-requests-limit'];
      const requestsRemaining = error.response.headers?.['x-ratelimit-requests-remaining'];
      const requestsReset = error.response.headers?.['x-ratelimit-requests-reset'];
      
      let rateLimitInfo = {};
      if (requestsLimit && requestsRemaining) {
        rateLimitInfo = {
          limit: requestsLimit,
          remaining: requestsRemaining,
          reset: requestsReset || 'N/A',
          usagePercentage: Number.parseInt(requestsRemaining, 10) / Number.parseInt(requestsLimit, 10) * 100
        };
        
        console.error(`API Rate Limits - Limit: ${requestsLimit}, Remaining: ${requestsRemaining}, Reset: ${requestsReset || 'N/A'}`);
      }
      
      // If we got a 403 error, it could be due to an invalid API key or exceeded quota
      if (error.response.status === 403) {
        const errorMessage = error.response.data?.message || "Authentication failed";
        
        // Check if the error message indicates a quota issue
        if (errorMessage.includes("quota") || errorMessage.includes("limit") || errorMessage.includes("exceeded")) {
          currentApiStatus = dataSourceStatusChanged(API_STATUS.OFFLINE);
          return { 
            valid: false, 
            error: "API quota exceeded", 
            status: error.response.status,
            data: error.response.data,
            rateLimitInfo,
            quotaExceeded: true
          };
        }
        
        // Otherwise, it's likely an authentication issue
        currentApiStatus = dataSourceStatusChanged(API_STATUS.OFFLINE);
        return { 
          valid: false, 
          error: "API key authentication failed", 
          status: error.response.status,
          data: error.response.data,
          rateLimitInfo
        };
      }
    }
    
    currentApiStatus = dataSourceStatusChanged(API_STATUS.OFFLINE);
    
    return { 
      valid: false, 
      error: error.message,
      status: error.response?.status,
      data: error.response?.data
    };
  }
};

// Enhanced mock data for fallback when API is rate limited
const MOCK_DATA = {
  search: {
    items: [
      {
        id: { videoId: 'mock1' },
        snippet: {
          title: 'Supercross 2024 Highlights - Round 1',
          channelTitle: 'SupercrossLIVE',
          description: 'Highlights from the opening round of the 2024 Monster Energy Supercross season',
          thumbnails: { high: { url: 'https://i.ytimg.com/vi/mock1/hqdefault.jpg' } },
          channelId: 'UCkaNo2FUEWips2z4BkOHl6Q',
          publishedAt: new Date().toISOString()
        }
      },
      {
        id: { videoId: 'mock2' },
        snippet: {
          title: 'Pro Motocross 2024 - Race Highlights',
          channelTitle: 'Pro Motocross',
          description: 'Full highlights from the Pro Motocross Championship',
          thumbnails: { high: { url: 'https://i.ytimg.com/vi/mock2/hqdefault.jpg' } },
          channelId: 'UC_gkm2w-CpdXbFPRgYk5Qkw',
          publishedAt: new Date(Date.now() - 86400000).toISOString() // 1 day ago
        }
      },
      {
        id: { videoId: 'mock3' },
        snippet: {
          title: 'Hard Enduro World Championship - Extreme Racing',
          channelTitle: 'Red Bull Motorsports',
          description: 'The toughest enduro race in the world',
          thumbnails: { high: { url: 'https://i.ytimg.com/vi/mock3/hqdefault.jpg' } },
          channelId: 'UCNHm2I4VILXvvANDvrH9Kdg',
          publishedAt: new Date(Date.now() - 172800000).toISOString() // 2 days ago
        }
      },
      {
        id: { videoId: 'mock4' },
        snippet: {
          title: 'MXGP 2024 Season Preview - Top Riders and Teams',
          channelTitle: 'MXGP-TV',
          description: 'Preview of the upcoming MXGP season with analysis of top contenders',
          thumbnails: { high: { url: 'https://i.ytimg.com/vi/mock4/hqdefault.jpg' } },
          channelId: 'UCpz_0gjWO8J5RnNjKe9YLMw',
          publishedAt: new Date(Date.now() - 259200000).toISOString() // 3 days ago
        }
      },
      {
        id: { videoId: 'mock5' },
        snippet: {
          title: 'Freestyle Motocross Best Tricks Compilation 2024',
          channelTitle: 'X Games',
          description: 'The most incredible FMX tricks from recent competitions',
          thumbnails: { high: { url: 'https://i.ytimg.com/vi/mock5/hqdefault.jpg' } },
          channelId: 'UCblfuW_4rakIf2h6aqANefA',
          publishedAt: new Date(Date.now() - 345600000).toISOString() // 4 days ago
        }
      }
    ]
  }
};

// Rate limiting tracking
let rateLimitHits = 0;
let lastRateLimitReset = Date.now();
const RATE_LIMIT_RESET_INTERVAL = 60 * 60 * 1000; // 1 hour

// API request queue to prevent parallel requests
const requestQueue = [];
let isProcessingQueue = false;

// Process the request queue sequentially
const processQueue = async () => {
  if (isProcessingQueue || requestQueue.length === 0) return;
  
  isProcessingQueue = true;
  
  try {
    const nextRequest = requestQueue.shift();
    const result = await nextRequest.execute();
    nextRequest.resolve(result);
  } catch (error) {
    const nextRequest = requestQueue[0];
    if (nextRequest) {
      nextRequest.reject(error);
    }
  } finally {
    isProcessingQueue = false;
    
    // Continue processing queue with a delay to prevent rate limiting
    if (requestQueue.length > 0) {
      setTimeout(() => processQueue(), 1000); // 1 second delay between requests
    }
  }
};

// Add request to queue
const queueRequest = (executeFunction) => {
  return new Promise((resolve, reject) => {
    requestQueue.push({
      execute: executeFunction,
      resolve,
      reject
    });
    
    if (!isProcessingQueue) {
      processQueue();
    }
  });
};

// Retry configuration
const MAX_RETRIES = 1; // Reduce retries to minimize API calls
const RETRY_DELAY = 2000; // Increase delay to 2 seconds

// Track API call performance and emit event for debug panel
export const trackApiCall = (endpoint, time, success) => {
  // Emit custom event for debug panel
  const event = new CustomEvent('api-call', { 
    detail: { 
      endpoint,
      time,
      success
    } 
  });
  window.dispatchEvent(event);
  
  // Also track in analytics if available
  trackApiPerformance(endpoint, time, success);
};

// Check if we should use mock data based on rate limit hits
const shouldUseMockData = () => {
  // If we've hit rate limits 5 or more times recently, use mock data
  return rateLimitHits >= 5;
};

export const fetchFromAPI = async (endpoint, retryCount = 0) => {
  const startTime = performance.now();
  const cacheKey = endpoint;
  
  // Reset rate limit counter if enough time has passed
  if (Date.now() - lastRateLimitReset > RATE_LIMIT_RESET_INTERVAL) {
    rateLimitHits = 0;
    lastRateLimitReset = Date.now();
  }
  
  // If we're hitting too many rate limits, use mock data for search endpoints
  if (shouldUseMockData() && endpoint.includes('search')) {
    console.warn("Too many rate limit errors recently, using mock data");
    logEvent('api_fallback', { endpoint, reason: 'excessive_rate_limits' });
    
    // Update API status to offline
    currentApiStatus = dataSourceStatusChanged(API_STATUS.OFFLINE);
    
    const elapsedTime = performance.now() - startTime;
    trackApiCall(endpoint, elapsedTime, true);
    
    return MOCK_DATA.search;
  }
  
  // Check cache first
  if (apiCache.has(cacheKey)) {
    const cachedData = apiCache.get(cacheKey);
    if (cachedData.expiry > Date.now()) {
      console.log("Using cached data for:", endpoint);
      const elapsedTime = performance.now() - startTime;
      trackApiCall(endpoint, elapsedTime, true);
      return cachedData.data;
    }
    
    // Remove expired cache entry
    apiCache.delete(cacheKey);
  }
  
  // Queue the API request to prevent parallel calls
  return queueRequest(async () => {
    // Parse the endpoint to extract query parameters
    const [path, queryString] = endpoint.split('?');
    const params = new URLSearchParams(queryString);
    
    // Map old API parameters to new API format
    const newParams = {};
    
    // Handle different endpoint types
    if (path.includes('search')) {
      // For search endpoints
      if (params.has('q')) {
        newParams.q = params.get('q');
      } else if (params.has('channelId')) {
        // Channel search - use the channel ID as a filter
        newParams.q = '';
        newParams.channelId = params.get('channelId');
      }
      
      // Add language and region parameters
      newParams.hl = 'en';
      newParams.gl = userCountry;
    } else if (path.includes('channels')) {
      // For channel details
      newParams.id = params.get('id');
    } else if (path.includes('videos')) {
      // For video details
      newParams.id = params.get('id');
    }
    
    // Determine the correct endpoint for the new API
    let newEndpoint = 'search/';
    if (path.includes('channels')) {
      newEndpoint = 'channel/details/';
    } else if (path.includes('videos')) {
      newEndpoint = 'video/details/';
    }
    
    // Check if API key is available
    const apiKey = import.meta.env.VITE_RAPID_API_KEY;
    if (!apiKey) {
      console.error("API Key is missing! Make sure VITE_RAPID_API_KEY is set in your environment variables.");
      currentApiStatus = dataSourceStatusChanged(API_STATUS.OFFLINE);
      throw new Error("API Key is missing");
    }
    
    // Configure request options
    const options = {
      method: 'GET',
      url: `${BASE_URL}/${newEndpoint}`,
      params: newParams,
      headers: {
        'X-RapidAPI-Key': apiKey,
        'X-RapidAPI-Host': 'youtube138.p.rapidapi.com'
      },
      // Add timeout to prevent hanging requests
      timeout: 10000
    };
    
    console.log(`Fetching from API: ${options.url}`);
    console.log(`With params: ${JSON.stringify(options.params)}`);
    console.log(`Using API key: ${options.headers['X-RapidAPI-Key']?.substring(0, 5)}...`);
    
    try {
      const response = await axios.request(options);
      
      // Log rate limit headers if they exist
      const requestsLimit = response.headers['x-ratelimit-requests-limit'];
      const requestsRemaining = response.headers['x-ratelimit-requests-remaining'];
      const requestsReset = response.headers['x-ratelimit-requests-reset'];
      
      if (requestsLimit && requestsRemaining) {
        console.log(`API Rate Limits - Limit: ${requestsLimit}, Remaining: ${requestsRemaining}, Reset: ${requestsReset || 'N/A'}`);
        
        // Emit custom event with rate limit info
        const event = new CustomEvent('api-rate-limit-info', { 
          detail: { 
            limit: requestsLimit,
            remaining: requestsRemaining,
            reset: requestsReset
          } 
        });
        window.dispatchEvent(event);
        
        // If we're close to the limit (less than 10% remaining), log a warning
        if (Number.parseInt(requestsRemaining, 10) / Number.parseInt(requestsLimit, 10) < 0.1) {
          console.warn(`API Rate Limit Warning: Only ${requestsRemaining} requests remaining out of ${requestsLimit}`);
          logEvent('api_rate_limit_warning', { 
            remaining: requestsRemaining,
            limit: requestsLimit
          });
        }
      }
      
      const { data } = response;
      console.log("API response received:", data);
      
      // Update API status to online
      currentApiStatus = dataSourceStatusChanged(API_STATUS.ONLINE);
      
      // Transform the response to match the expected format from the old API
      const transformedData = transformResponse(data, path);
      console.log("Transformed data:", transformedData);
      
      // Cache the successful response
      apiCache.set(cacheKey, {
        data: transformedData,
        expiry: Date.now() + CACHE_TTL
      });
      
      // Track successful API call
      const elapsedTime = performance.now() - startTime;
      trackApiCall(endpoint, elapsedTime, true);
      
      // Reset rate limit hits counter on successful request
      rateLimitHits = Math.max(0, rateLimitHits - 1);
      
      return transformedData;
    } catch (error) {
      // Track failed API call
      const elapsedTime = performance.now() - startTime;
      trackApiCall(endpoint, elapsedTime, false);
      
      // Enhanced error logging
      console.error("API Error Details:");
      console.error(`- Status: ${error.response?.status}`);
      console.error(`- Status Text: ${error.response?.statusText}`);
      console.error(`- URL: ${options.url}`);
      console.error(`- Params: ${JSON.stringify(options.params)}`);
      console.error(`- Headers: X-RapidAPI-Key: ${options.headers['X-RapidAPI-Key']?.substring(0, 5)}..., X-RapidAPI-Host: ${options.headers['X-RapidAPI-Host']}`);
      
      if (error.response?.data) {
        console.error(`- Response Data: ${JSON.stringify(error.response.data)}`);
      }
      
      // Check for rate limit headers in error response
      if (error.response?.headers) {
        const requestsLimit = error.response.headers['x-ratelimit-requests-limit'];
        const requestsRemaining = error.response.headers['x-ratelimit-requests-remaining'];
        const requestsReset = error.response.headers['x-ratelimit-requests-reset'];
        
        if (requestsLimit && requestsRemaining) {
          console.error(`API Rate Limits - Limit: ${requestsLimit}, Remaining: ${requestsRemaining}, Reset: ${requestsReset || 'N/A'}`);
        }
      }
      
      // Handle rate limiting errors
      if (error.response && (error.response.status === 429 || error.response.status === 403)) {
        console.error("API Error: Rate limit exceeded or authentication failed");
        rateLimitHits++;
        
        // Log the rate limit error
        logEvent('api_rate_limit', { 
          endpoint, 
          retryCount,
          rateLimitHits,
          status: error.response.status,
          statusText: error.response.statusText
        });
        
        // If we have retries left, attempt a retry with exponential backoff
        if (retryCount < MAX_RETRIES) {
          const delay = RETRY_DELAY * (2 ** retryCount);
          console.log(`Retrying in ${delay}ms (attempt ${retryCount + 1}/${MAX_RETRIES})`);
          
          return new Promise(resolve => {
            setTimeout(() => {
              resolve(fetchFromAPI(endpoint, retryCount + 1));
            }, delay);
          });
        }
        
        // If we've exhausted retries or hit too many rate limits, use mock data
        console.warn("Rate limit exceeded or authentication failed, using mock data");
        
        // Update API status to offline
        currentApiStatus = dataSourceStatusChanged(API_STATUS.OFFLINE);
        
        if (endpoint.includes('search')) {
          return MOCK_DATA.search;
        }
      }
      
      console.error("API Error:", error.response?.data || { message: error.message });
      
      // Update API status to offline for any error
      currentApiStatus = dataSourceStatusChanged(API_STATUS.OFFLINE);
      
      throw error;
    }
  });
};

// Transform the response from the new API format to match the old API format
function transformResponse(data, endpoint) {
  if (endpoint.includes('search')) {
    // Transform search results
    if (data.contents) {
      return {
        items: data.contents
          .filter(item => item && (item.type === 'video' || item.type === 'channel'))
          .map(item => {
            // Handle video type
            if (item.type === 'video' && item.video) {
              return {
                id: {
                  kind: 'youtube#video',
                  videoId: item.video.videoId
                },
                snippet: {
                  publishedAt: item.video.publishedTimeText,
                  channelId: item.video.author?.channelId,
                  title: item.video.title,
                  description: item.video.descriptionSnippet || '',
                  thumbnails: {
                    high: {
                      url: item.video.thumbnails?.[0]?.url || ''
                    }
                  },
                  channelTitle: item.video.author?.title || '',
                  liveBroadcastContent: item.video.isLiveNow ? 'live' : 'none'
                }
              };
            } 
            
            // Handle channel type
            if (item.type === 'channel' && item.channel) {
              return {
                id: {
                  kind: 'youtube#channel',
                  channelId: item.channel.channelId
                },
                snippet: {
                  publishedAt: '',
                  channelId: item.channel.channelId,
                  title: item.channel.title,
                  description: '',
                  thumbnails: {
                    high: {
                      url: item.channel.avatar?.[0]?.url || ''
                    }
                  },
                  channelTitle: item.channel.title
                }
              };
            }
            
            return null;
          })
          .filter(item => item !== null)
      };
    }
    
    // Fallback for empty or unexpected response
    return { items: [] };
  }
  
  if (endpoint.includes('channels')) {
    // Transform channel details
    return {
      items: [{
        id: data.id,
        snippet: {
          title: data.title,
          description: data.description,
          thumbnails: {
            high: {
              url: data.avatar?.[0]?.url || ''
            }
          }
        },
        statistics: {
          subscriberCount: data.stats?.subscribers,
          viewCount: data.stats?.views,
          videoCount: data.stats?.videos
        }
      }]
    };
  }
  
  if (endpoint.includes('videos')) {
    // Transform video details
    return {
      items: [{
        id: data.id,
        snippet: {
          publishedAt: data.publishedDate,
          channelId: data.author?.channelId,
          title: data.title,
          description: data.description,
          thumbnails: {
            high: {
              url: data.thumbnails?.[0]?.url || ''
            }
          },
          channelTitle: data.author?.title || ''
        },
        statistics: {
          viewCount: data.stats?.views,
          likeCount: data.stats?.likes,
          commentCount: data.stats?.comments
        }
      }]
    };
  }
  
  // Default return if no transformation matches
  return data;
}