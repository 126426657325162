import React from "react";
import { Box, Typography, Stack, Divider } from "@mui/material";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#0f0f0f",
        color: "#fff",
        p: { xs: 3, md: 5 },
        mt: 2,
        borderTop: "1px solid #3d3d3d",
      }}
    >
      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "center", md: "flex-start" }}
        spacing={4}
      >
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
            <Box component="img" src="/motovids-logo.png" alt="MotoVids Logo" sx={{ width: '1.8rem', height: '1.8rem' }} />
            <Typography 
              variant="h5" 
              fontWeight="700" 
              fontFamily="'Montserrat', sans-serif"
              letterSpacing="0.5px"
            >
              Moto<Box component="span" sx={{ color: "#FC1503" }}>Vids</Box>
            </Typography>
          </Box>
          <Typography 
            variant="body2" 
            color="gray" 
            mb={1}
            fontFamily="'Roboto', sans-serif"
            fontSize="0.9rem"
          >
            The best motorcycle video platform
          </Typography>
          <Typography 
            variant="body2" 
            color="gray"
            fontFamily="'Roboto', sans-serif"
            fontSize="0.9rem"
          >
            © {new Date().getFullYear()} MotoVids. All rights reserved.
          </Typography>
        </Box>

        <Box>
          <Typography 
            variant="subtitle1" 
            fontWeight="600" 
            mb={2}
            fontFamily="'Montserrat', sans-serif"
            fontSize="1.1rem"
          >
            Categories
          </Typography>
          <Stack spacing={1}>
            <Link 
              to="/" 
              style={{
                color: "gray",
                textDecoration: "none",
                fontFamily: "'Roboto', sans-serif",
                fontSize: "0.9rem",
              }}
              onMouseOver={(e) => e.target.style.color = '#FC1503'}
              onMouseOut={(e) => e.target.style.color = 'gray'}
            >
              Supercross
            </Link>
            <Link 
              to="/" 
              style={{
                color: "gray",
                textDecoration: "none",
                fontFamily: "'Roboto', sans-serif",
                fontSize: "0.9rem",
              }}
              onMouseOver={(e) => e.target.style.color = '#FC1503'}
              onMouseOut={(e) => e.target.style.color = 'gray'}
            >
              Motocross
            </Link>
            <Link 
              to="/" 
              style={{
                color: "gray",
                textDecoration: "none",
                fontFamily: "'Roboto', sans-serif",
                fontSize: "0.9rem",
              }}
              onMouseOver={(e) => e.target.style.color = '#FC1503'}
              onMouseOut={(e) => e.target.style.color = 'gray'}
            >
              Hard Enduro
            </Link>
            <Link 
              to="/" 
              style={{
                color: "gray",
                textDecoration: "none",
                fontFamily: "'Roboto', sans-serif",
                fontSize: "0.9rem",
              }}
              onMouseOver={(e) => e.target.style.color = '#FC1503'}
              onMouseOut={(e) => e.target.style.color = 'gray'}
            >
              Motorcycle Racing
            </Link>
          </Stack>
        </Box>

        <Box>
          <Typography 
            variant="subtitle1" 
            fontWeight="600" 
            mb={2}
            fontFamily="'Montserrat', sans-serif"
            fontSize="1.1rem"
          >
            Legal
          </Typography>
          <Stack spacing={1}>
            <Link 
              to="/privacy" 
              style={{
                color: "gray",
                textDecoration: "none",
                fontFamily: "'Roboto', sans-serif",
                fontSize: "0.9rem",
              }}
              onMouseOver={(e) => e.target.style.color = '#FC1503'}
              onMouseOut={(e) => e.target.style.color = 'gray'}
            >
              Privacy Policy
            </Link>
            <Link 
              to="/terms" 
              style={{
                color: "gray",
                textDecoration: "none",
                fontFamily: "'Roboto', sans-serif",
                fontSize: "0.9rem",
              }}
              onMouseOver={(e) => e.target.style.color = '#FC1503'}
              onMouseOut={(e) => e.target.style.color = 'gray'}
            >
              Terms of Service
            </Link>
          </Stack>
        </Box>
      </Stack>

      <Divider sx={{ my: 3, borderColor: '#3d3d3d' }} />

      <Typography 
        variant="body2" 
        color="gray" 
        textAlign="center"
        fontFamily="'Roboto', sans-serif"
        fontSize="0.8rem"
      >
        All videos are sourced from YouTube and belong to their respective owners.
      </Typography>
    </Box>
  );
};

export default Footer;

