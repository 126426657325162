/**
 * Utility functions for video data manipulation
 */

/**
 * Enhances video objects with mock statistics if they don't have them
 * @param {Array} videos - Array of video objects
 * @returns {Array} - Enhanced video objects with statistics
 */
export const enhanceVideosWithStats = (videos) => {
  if (!videos || !Array.isArray(videos)) return [];
  
  return videos.map(video => {
    // Create a copy of the video to avoid mutating the original
    const enhancedVideo = { ...video };
    
    // Ensure snippet exists
    if (!enhancedVideo.snippet) {
      enhancedVideo.snippet = {};
    }
    
    // Ensure publishedAt exists and is valid
    if (!enhancedVideo.snippet.publishedAt) {
      // Generate a random date within the last 2 years
      const now = new Date();
      const twoYearsAgo = new Date();
      twoYearsAgo.setFullYear(now.getFullYear() - 2);
      
      const randomTimestamp = twoYearsAgo.getTime() + Math.random() * (now.getTime() - twoYearsAgo.getTime());
      enhancedVideo.snippet.publishedAt = new Date(randomTimestamp).toISOString();
    }
    
    // Skip adding statistics if they already exist
    if (enhancedVideo.statistics) return enhancedVideo;
    
    // Generate random statistics
    const viewCount = Math.floor(Math.random() * 1000000) + 1000;
    const likeCount = Math.floor(viewCount * (Math.random() * 0.1 + 0.01)); // 1-11% of views
    const commentCount = Math.floor(viewCount * (Math.random() * 0.02 + 0.001)); // 0.1-2.1% of views
    
    // Generate random duration (2-15 minutes)
    const minutes = Math.floor(Math.random() * 13) + 2;
    const seconds = Math.floor(Math.random() * 60);
    const duration = `PT${minutes}M${seconds}S`;
    
    enhancedVideo.statistics = {
      viewCount,
      likeCount,
      commentCount,
      favoriteCount: 0,
      dislikeCount: 0
    };
    
    enhancedVideo.contentDetails = {
      ...enhancedVideo.contentDetails,
      duration
    };
    
    return enhancedVideo;
  });
};

/**
 * Formats a duration string from ISO 8601 format to human-readable format
 * @param {string} duration - ISO 8601 duration string (e.g., "PT1H30M15S")
 * @returns {string} - Formatted duration (e.g., "1:30:15")
 */
export const formatDuration = (duration) => {
  if (!duration) return '0:00';
  
  // Match hours, minutes, and seconds
  const match = duration.match(/PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/);
  if (!match) return '0:00';
  
  const hours = match[1] ? Number.parseInt(match[1], 10) : 0;
  const minutes = match[2] ? Number.parseInt(match[2], 10) : 0;
  const seconds = match[3] ? Number.parseInt(match[3], 10) : 0;
  
  // Format based on whether there are hours
  if (hours > 0) {
    return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
  
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};

/**
 * Formats a view count to a human-readable format
 * @param {number|string} viewCount - The number of views
 * @returns {string} - Formatted view count (e.g., "1.2M views")
 */
export const formatViewCount = (viewCount) => {
  if (!viewCount) return '0 views';
  
  const count = Number.parseInt(viewCount, 10);
  if (count >= 1000000) {
    return `${(count / 1000000).toFixed(1)}M views`;
  }
  
  if (count >= 1000) {
    return `${(count / 1000).toFixed(1)}K views`;
  }
  
  return `${count} views`;
};

/**
 * Formats a published date to a relative time string
 * @param {string} publishedAt - ISO date string
 * @returns {string} - Relative time (e.g., "2 years ago")
 */
export const formatPublishedDate = (publishedAt) => {
  if (!publishedAt) return '';
  
  try {
    const published = new Date(publishedAt);
    
    // Check if the date is valid
    if (Number.isNaN(published.getTime())) {
      console.warn('Invalid date format:', publishedAt);
      return '';
    }
    
    const now = new Date();
    
    const diffTime = Math.abs(now - published);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const diffMonths = Math.floor(diffDays / 30);
    const diffYears = Math.floor(diffDays / 365);
    
    if (diffYears > 0) {
      return `${diffYears} ${diffYears === 1 ? 'year' : 'years'} ago`;
    }
    
    if (diffMonths > 0) {
      return `${diffMonths} ${diffMonths === 1 ? 'month' : 'months'} ago`;
    }
    
    if (diffDays > 0) {
      return `${diffDays} ${diffDays === 1 ? 'day' : 'days'} ago`;
    }
    
    const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
    if (diffHours > 0) {
      return `${diffHours} ${diffHours === 1 ? 'hour' : 'hours'} ago`;
    }
    
    const diffMinutes = Math.floor(diffTime / (1000 * 60));
    if (diffMinutes > 0) {
      return `${diffMinutes} ${diffMinutes === 1 ? 'minute' : 'minutes'} ago`;
    }
    
    return 'Just now';
  } catch (error) {
    console.error('Error formatting date:', error);
    return '';
  }
};

/**
 * Filter out videos that are likely to be region-restricted
 * @param {Array} videos - Array of video objects
 * @param {String} userCountry - User's country code
 * @returns {Array} - Filtered array of videos
 */
export const filterRegionRestrictedVideos = (videos, userCountry = 'global') => {
  if (!videos || !Array.isArray(videos)) return [];
  
  // Keywords that might indicate region restrictions
  const restrictionKeywords = [
    'not available in your country',
    'region restricted',
    'geo-restricted',
    'only available in',
    'blocked in your country',
    'not available in',
    'copyright claim'
  ];
  
  // Countries with most restrictive content policies
  const restrictiveCountries = ['de', 'kr', 'jp', 'cn', 'ru', 'sa'];
  
  // If user is in a country with restrictive content policies, be more aggressive with filtering
  const isRestrictiveRegion = restrictiveCountries.includes(userCountry.toLowerCase());
  
  return videos.filter(video => {
    const title = video.snippet?.title?.toLowerCase() || '';
    const description = video.snippet?.description?.toLowerCase() || '';
    const channelTitle = video.snippet?.channelTitle?.toLowerCase() || '';
    
    // Skip videos with known region restriction indicators in title or description
    const hasRestrictionKeywords = restrictionKeywords.some(keyword => 
      title.includes(keyword) || description.includes(keyword)
    );
    
    // Skip videos from official TV networks if in restrictive region (they often geo-restrict)
    const isLikelyRestricted = isRestrictiveRegion && (
      channelTitle.includes('official') && 
      (channelTitle.includes('tv') || channelTitle.includes('network') || channelTitle.includes('broadcast'))
    );
    
    return !hasRestrictionKeywords && !isLikelyRestricted;
  });
};

/**
 * Filter videos to ensure they're motorcycle-related
 * @param {Array} videos - Array of video objects
 * @returns {Array} - Filtered array of motorcycle-related videos
 */
export const filterMotorcycleVideos = (videos) => {
  if (!videos || !Array.isArray(videos)) return [];
  
  // Motorcycle-related keywords
  const motoKeywords = [
    'motorcycle', 'motorbike', 'supercross', 'motocross', 'enduro', 
    'dirt bike', 'dirtbike', 'bike racing', 'moto', 'mx', 'sx', 
    'trials', 'hard enduro', 'supermoto', 'rally raid', 'dakar',
    'freestyle', 'fmx', 'stunt', 'yamaha', 'honda', 'kawasaki', 
    'suzuki', 'ktm', 'husqvarna', 'gas gas', 'beta', 'triumph'
  ];
  
  // Blacklisted keywords that indicate irrelevant content
  const blacklistedKeywords = [
    'gta', 'grand theft auto', 'video game', 'gameplay', 'gaming',
    'animation', 'cartoon', 'toy', 'rc', 'remote control',
    'unboxing', 'review', 'reaction', 'react', 'reacting',
    'bicycle', 'mountain bike', 'mtb', 'e-bike', 'ebike'
  ];
  
  return videos.filter(video => {
    const title = video.snippet?.title?.toLowerCase() || '';
    const description = video.snippet?.description?.toLowerCase() || '';
    const channelTitle = video.snippet?.channelTitle?.toLowerCase() || '';
    const tags = video.snippet?.tags || [];
    
    // Check if video has motorcycle-related keywords
    const hasMotoKeyword = motoKeywords.some(keyword => 
      title.includes(keyword) || 
      description.includes(keyword) ||
      channelTitle.includes(keyword) ||
      tags.some(tag => tag.toLowerCase().includes(keyword))
    );
    
    // Check if video has blacklisted keywords
    const hasBlacklistedKeyword = blacklistedKeywords.some(keyword => 
      title.includes(keyword) && !title.includes(`motorcycle ${keyword}`)
    );
    
    return hasMotoKeyword && !hasBlacklistedKeyword;
  });
};

/**
 * Combined filter function to get motorcycle videos available in user's region
 * @param {Array} videos - Array of video objects
 * @param {String} userCountry - User's country code
 * @returns {Array} - Filtered array of motorcycle videos available in user's region
 */
export const getAvailableMotorcycleVideos = (videos, userCountry = 'global') => {
  if (!videos || !Array.isArray(videos)) return [];
  
  // First filter out region-restricted videos
  const regionFiltered = filterRegionRestrictedVideos(videos, userCountry);
  
  // Then filter for motorcycle content
  return filterMotorcycleVideos(regionFiltered);
};

/**
 * More lenient filter function for motorcycle videos
 * @param {Array} videos - Array of video objects
 * @param {String} categoryName - Optional category name for more specific filtering
 * @returns {Array} - Filtered array with a more lenient approach
 */
export const lenientMotorcycleFilter = (videos, categoryName = '') => {
  if (!videos || !Array.isArray(videos)) return [];
  
  // Expanded motorcycle-related keywords by category
  const motoKeywords = {
    // Motorcycle types
    types: [
      'motorcycle', 'motorbike', 'bike', 'superbike', 'sportbike', 'cruiser', 
      'chopper', 'bobber', 'cafe racer', 'scrambler', 'dirt bike', 'dirtbike',
      'adventure bike', 'adv bike', 'dual sport', 'touring', 'bagger', 'trike'
    ],
    
    // Racing and sports
    racing: [
      'supercross', 'motocross', 'mx', 'sx', 'arenacross', 'enduro', 
      'hard enduro', 'rally raid', 'dakar', 'isle of man tt', 'road racing',
      'gp', 'motogp', 'moto gp', 'wsbk', 'ama', 'fim', 'race', 'racing'
    ],
    
    // Riding styles and activities
    riding: [
      'riding', 'rider', 'wheelie', 'stoppie', 'burnout', 'stunt', 'freestyle',
      'fmx', 'jump', 'trail', 'offroad', 'off-road', 'track day', 'twisties'
    ],
    
    // Brands and manufacturers
    brands: [
      'yamaha', 'honda', 'kawasaki', 'suzuki', 'ktm', 'husqvarna', 'gas gas',
      'beta', 'triumph', 'ducati', 'harley', 'davidson', 'bmw', 'royal enfield',
      'aprilia', 'indian', 'victory', 'zero', 'buell', 'mv agusta', 'moto guzzi'
    ],
    
    // Equipment and gear
    gear: [
      'helmet', 'leathers', 'boots', 'gloves', 'jacket', 'gear', 'protection',
      'armor', 'exhaust', 'muffler', 'suspension', 'tires', 'brakes'
    ],
    
    // Events and shows
    events: [
      'bike week', 'rally', 'meet', 'show', 'exhibition', 'sturgis', 'daytona'
    ]
  };
  
  // Category-specific keywords for more targeted filtering
  const categoryKeywords = {
    'Supercross': ['supercross', 'sx', 'ama supercross', 'monster energy', 'stadium', 'triple crown'],
    'AMA Supercross': ['ama supercross', 'monster energy', 'supercross', 'sx', 'stadium'],
    'Motocross': ['motocross', 'mx', 'outdoor', 'national', 'pro motocross'],
    'MXGP': ['mxgp', 'world championship', 'fim motocross', 'grand prix'],
    'Hard Enduro': ['hard enduro', 'extreme enduro', 'romaniacs', 'erzberg', 'hare scramble'],
    'Moto Freestyle': ['freestyle', 'fmx', 'x games', 'backflip', 'trick', 'nitro circus'],
    'Moto Freeride': ['freeride', 'freeriding', 'natural terrain', 'backcountry'],
    'Trials': ['trials', 'trial', 'observed trials', 'competition'],
    'Motobike Fails': ['crash', 'fail', 'accident', 'compilation', 'wrecks'],
    'Motorcycle Stunts': ['stunt', 'wheelie', 'stoppie', 'burnout', 'drift'],
    'Motorcycle Maintenance': ['maintenance', 'repair', 'how to', 'diy', 'fix', 'tutorial'],
    'Motorcycle Reviews': ['review', 'test ride', 'first ride', 'comparison', 'versus'],
    'Motorcycle Racing': ['race', 'racing', 'road race', 'track', 'circuit'],
    'Motorcycle Tutorials': ['tutorial', 'how to', 'guide', 'tips', 'learn']
  };
  
  // Flatten all keywords into a single array for easy checking
  const allMotoKeywords = Object.values(motoKeywords).flat();
  
  // Get category-specific keywords if a category is provided
  const specificKeywords = categoryName && categoryKeywords[categoryName] 
    ? categoryKeywords[categoryName] 
    : [];
  
  // Reduced and more specific blacklisted keywords
  const blacklistedKeywords = [
    'gta', 'grand theft auto', 'video game', 'gameplay',
    'animation', 'cartoon', 'toy', 'rc', 'remote control',
    'bicycle race', 'bicycle racing', 'mountain bike', 'mtb'
  ];
  
  // Strong indicators that content is definitely motorcycle-related
  const strongIndicators = [
    'motorcycle', 'motorbike', 'supercross', 'motocross', 
    'motogp', 'superbike', 'yamaha r1', 'yamaha r6', 'honda cbr',
    'kawasaki ninja', 'harley davidson', 'ducati'
  ];
  
  // Add category name as a strong indicator if it's a specific motorcycle category
  if (categoryName && categoryName.toLowerCase().includes('moto')) {
    strongIndicators.push(categoryName.toLowerCase());
  }
  
  return videos.filter(video => {
    if (!video || !video.snippet) return false;
    
    const title = video.snippet?.title?.toLowerCase() || '';
    const description = video.snippet?.description?.toLowerCase() || '';
    const channelTitle = video.snippet?.channelTitle?.toLowerCase() || '';
    const tags = video.snippet?.tags || [];
    
    // Check for category-specific keywords first if we have a category
    if (specificKeywords.length > 0) {
      const hasSpecificKeyword = specificKeywords.some(keyword => 
        title.includes(keyword) || 
        description.includes(keyword) ||
        channelTitle.includes(keyword) ||
        (tags && tags.some(tag => tag.toLowerCase().includes(keyword)))
      );
      
      // If it has a specific keyword for this category, it's highly likely to be relevant
      if (hasSpecificKeyword) return true;
    }
    
    // Check for strong indicators - if found, include the video regardless
    const hasStrongIndicator = strongIndicators.some(indicator => 
      title.includes(indicator) || 
      channelTitle.includes(indicator)
    );
    
    if (hasStrongIndicator) return true;
    
    // Check if channel name contains motorcycle-related terms
    const channelHasMotoKeyword = allMotoKeywords.some(keyword => 
      channelTitle.includes(keyword)
    );
    
    // If the channel is clearly motorcycle-related, include all its videos
    if (channelHasMotoKeyword && 
        (channelTitle.includes('moto') || 
         channelTitle.includes('bike') || 
         channelTitle.includes('racing'))) {
      return true;
    }
    
    // Check if video has motorcycle-related keywords in title, description or tags
    const hasMotoKeyword = allMotoKeywords.some(keyword => 
      title.includes(keyword) || 
      description.includes(keyword) ||
      tags?.some(tag => tag.toLowerCase().includes(keyword))
    );
    
    // Check if video has blacklisted keywords that indicate irrelevant content
    const hasBlacklistedKeyword = blacklistedKeywords.some(keyword => 
      title.includes(keyword) && 
      !title.includes('motorcycle') && 
      !title.includes('motorbike')
    );
    
    // Special case for "bike" keyword which can be ambiguous
    if (title.includes('bike') && !hasBlacklistedKeyword) {
      // If title has "bike" but also has bicycle-related terms, exclude it
      const hasBicycleTerms = ['bicycle', 'pedal', 'cycling', 'cyclist', 'mtb', 'mountain bike'].some(
        term => title.includes(term) || description.includes(term)
      );
      
      if (!hasBicycleTerms) return true;
    }
    
    // Include videos with motorcycle keywords that aren't blacklisted
    return hasMotoKeyword && !hasBlacklistedKeyword;
  });
};

/**
 * Combined filter function with more lenient motorcycle filtering
 * @param {Array} videos - Array of video objects
 * @param {String} userCountry - User's country code
 * @param {String} categoryName - Optional category name for more specific filtering
 * @returns {Array} - Filtered array of motorcycle videos available in user's region
 */
export const getLenientMotorcycleVideos = (videos, userCountry = 'global', categoryName = '') => {
  if (!videos || !Array.isArray(videos)) return [];
  
  // First filter out region-restricted videos
  const regionFiltered = filterRegionRestrictedVideos(videos, userCountry);
  
  // If we have very few videos after region filtering, skip motorcycle filtering
  if (regionFiltered.length < 5) {
    console.log("Very few videos after region filtering, skipping motorcycle filtering");
    return regionFiltered;
  }
  
  // Then apply lenient motorcycle filtering with category
  const motoFiltered = lenientMotorcycleFilter(regionFiltered, categoryName);
  
  // If we have very few videos after motorcycle filtering, return the region filtered ones
  if (motoFiltered.length < 5 && regionFiltered.length > 10) {
    console.log("Very few videos after motorcycle filtering, using region filtered videos instead");
    return regionFiltered;
  }
  
  return motoFiltered;
}; 