import React, { createContext, useState, useContext, useEffect } from 'react';

// Create the likes context
const LikesContext = createContext();

// Mock data for initial likes
const INITIAL_MOCK_LIKES = {
  // Format: videoId: { count: number, isLiked: boolean }
  'dQw4w9WgXcQ': { count: 1247, isLiked: false },
  'jNQXAC9IVRw': { count: 892, isLiked: false },
  'QH2-TGUlwu4': { count: 543, isLiked: false },
  'ZZ5LpwO-An4': { count: 789, isLiked: false },
  '9bZkp7q19f0': { count: 1532, isLiked: false },
};

export const LikesProvider = ({ children }) => {
  const [likes, setLikes] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  // Load likes from localStorage on mount
  useEffect(() => {
    const storedLikes = localStorage.getItem('motovids_likes');
    if (storedLikes) {
      try {
        const parsedLikes = JSON.parse(storedLikes);
        setLikes(parsedLikes);
      } catch (error) {
        console.error('Error parsing stored likes:', error);
        // If there's an error, use the mock data
        setLikes(INITIAL_MOCK_LIKES);
      }
    } else {
      // If no stored likes, use the mock data
      setLikes(INITIAL_MOCK_LIKES);
    }
    setIsLoaded(true);
  }, []);

  // Save likes to localStorage whenever they change
  useEffect(() => {
    if (isLoaded) {
      localStorage.setItem('motovids_likes', JSON.stringify(likes));
    }
  }, [likes, isLoaded]);

  // Toggle like for a video
  const toggleLike = (videoId) => {
    setLikes(prevLikes => {
      const videoLikes = prevLikes[videoId] || { count: 0, isLiked: false };
      const newCount = videoLikes.isLiked ? videoLikes.count - 1 : videoLikes.count + 1;
      
      return {
        ...prevLikes,
        [videoId]: {
          count: newCount,
          isLiked: !videoLikes.isLiked
        }
      };
    });
  };

  // Get like info for a video
  const getLikeInfo = (videoId) => {
    return likes[videoId] || { count: 0, isLiked: false };
  };

  // Check if a video is liked
  const isVideoLiked = (videoId) => {
    return likes[videoId]?.isLiked || false;
  };

  // Get like count for a video
  const getLikeCount = (videoId) => {
    return likes[videoId]?.count || 0;
  };

  // Get all liked videos
  const getLikedVideos = () => {
    return Object.entries(likes)
      .filter(([_, data]) => data.isLiked)
      .map(([videoId]) => videoId);
  };

  // Context value
  const value = {
    likes,
    toggleLike,
    getLikeInfo,
    isVideoLiked,
    getLikeCount,
    getLikedVideos,
    isLoaded
  };

  return <LikesContext.Provider value={value}>{children}</LikesContext.Provider>;
};

// Custom hook to use the likes context
export const useLikes = () => {
  const context = useContext(LikesContext);
  if (!context) {
    throw new Error('useLikes must be used within a LikesProvider');
  }
  return context;
};

export default LikesContext; 