import React from 'react';
import { Helmet } from 'react-helmet-async';

const SEO = ({ 
  title = 'MotoVids - The Best Motorcycle Video Platform',
  description = 'Watch the best motorcycle videos including Supercross, Motocross, Hard Enduro, and more. MotoVids is your ultimate destination for motorcycle content.',
  keywords = 'motorcycle videos, supercross, motocross, hard enduro, dirt bike videos, motorcycle racing',
  canonicalUrl,
  ogType = 'website',
  ogImage = '/motovids-og.jpg' // Default OG image
}) => {
  // Build the canonical URL
  const siteUrl = 'https://motovids.vercel.app';
  const canonical = canonicalUrl ? `${siteUrl}${canonicalUrl}` : siteUrl;

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={canonical} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content={ogType} />
      <meta property="og:url" content={canonical} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={`${siteUrl}${ogImage}`} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={canonical} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={`${siteUrl}${ogImage}`} />

      {/* Motorcycle-specific structured data */}
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'WebSite',
          'name': 'MotoVids',
          'url': siteUrl,
          'potentialAction': {
            '@type': 'SearchAction',
            'target': `${siteUrl}/search/{search_term_string}`,
            'query-input': 'required name=search_term_string'
          },
          'description': 'The best motorcycle video platform featuring Supercross, Motocross, Hard Enduro, and more.',
          'keywords': 'motorcycle videos, supercross, motocross, hard enduro, dirt bike videos'
        })}
      </script>
    </Helmet>
  );
};

export default SEO; 