import React from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Typography, 
  Box, 
  IconButton,
  useTheme,
  useMediaQuery
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ConstructionIcon from '@mui/icons-material/Construction';

const ComingSoonModal = ({ open, onClose, title, icon: IconComponent }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      fullScreen={fullScreen}
      PaperProps={{
        sx: {
          bgcolor: '#1e1e1e',
          color: 'white',
          borderRadius: 2,
          maxWidth: '500px',
          width: '100%'
        }
      }}
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
        pb: 2
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {IconComponent && <IconComponent sx={{ color: '#FC1503' }} />}
          <Typography variant="h6" component="div">
            {title || 'Coming Soon'}
          </Typography>
        </Box>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ py: 4 }}>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          textAlign: 'center',
          gap: 3
        }}>
          <ConstructionIcon sx={{ fontSize: 80, color: '#FC1503', mb: 2 }} />
          <Typography variant="h5" component="div" fontWeight="bold">
            Feature Coming Soon!
          </Typography>
          <Typography variant="body1" color="rgba(255, 255, 255, 0.7)" sx={{ maxWidth: '400px' }}>
            We're working hard to bring you the {title?.toLowerCase() || 'requested'} feature to enhance your MotoVids experience.
          </Typography>
          <Box sx={{ 
            bgcolor: 'rgba(252, 21, 3, 0.1)', 
            p: 2, 
            borderRadius: 2,
            mt: 2
          }}>
            <Typography variant="body2" color="rgba(255, 255, 255, 0.9)">
              In the meantime, you can browse our collection of curated motorcycle videos on the home page.
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ 
        borderTop: '1px solid rgba(255, 255, 255, 0.1)',
        px: 3,
        py: 2
      }}>
        <Button 
          onClick={onClose} 
          variant="contained"
          sx={{ 
            bgcolor: '#FC1503',
            '&:hover': {
              bgcolor: '#d01001',
            },
          }}
        >
          Got it
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ComingSoonModal; 