import { Analytics } from "@vercel/analytics/react";
import * as Sentry from "@sentry/react";

/**
 * Log an event to analytics platforms
 * @param {string} eventName - Name of the event
 * @param {Object} eventData - Event data/properties
 */
export const logEvent = (eventName, eventData = {}) => {
  try {
    // Log to Google Analytics if available
    if (window.gtag) {
      window.gtag('event', eventName, eventData);
    }
    
    // Log to console in development
    if (import.meta.env.DEV) {
      console.log(`[Analytics] ${eventName}`, eventData);
    }
  } catch (error) {
    console.error('Error logging analytics event:', error);
  }
};

/**
 * Initialize analytics services
 */
export const initAnalytics = () => {
  // This is a placeholder for future analytics initialization
  console.log('Analytics initialization placeholder');
};

/**
 * Track page view
 * @param {string} path - Current path
 * @param {string} title - Page title
 */
export const trackPageView = (path, title) => {
  logEvent('page_view', { path, title });
};

/**
 * Track API performance
 * @param {string} endpoint - API endpoint
 * @param {number} duration - Request duration in ms
 * @param {boolean} success - Whether the request was successful
 */
export const trackApiPerformance = (endpoint, duration, success) => {
  logEvent('api_performance', { endpoint, duration, success });
};

/**
 * Track error
 * @param {Error} error - Error object
 * @param {string} context - Error context
 * @param {Object} extraData - Additional data
 */
export const trackError = (error, context, extraData = {}) => {
  logEvent('error', { 
    message: error.message,
    context,
    ...extraData
  });
}; 