import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Paper, CircularProgress, Alert, IconButton, InputAdornment } from '@mui/material';
import { useAuth } from '../utils/AuthContext';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const AdminLogin = ({ onClose }) => {
  const { login, error, loading, clearError } = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!username || !password) return;
    
    const success = await login(username, password);
    if (success && onClose) {
      onClose();
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Paper 
      elevation={3} 
      sx={{ 
        p: 4, 
        maxWidth: '400px', 
        width: '100%', 
        mx: 'auto',
        bgcolor: '#1e1e1e',
        color: 'white',
        borderRadius: 2
      }}
    >
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          mb: 3
        }}
      >
        <AdminPanelSettingsIcon sx={{ fontSize: 48, color: '#FC1503', mb: 2 }} />
        <Typography variant="h5" component="h1" gutterBottom fontWeight="bold" color="white">
          Admin Login
        </Typography>
        <Typography variant="body2" color="rgba(255, 255, 255, 0.7)" textAlign="center">
          Enter your credentials to access the admin dashboard
        </Typography>
      </Box>

      {error && (
        <Alert 
          severity="error" 
          sx={{ 
            mb: 3,
            '& .MuiAlert-message': {
              color: '#f44336'
            },
            bgcolor: 'rgba(244, 67, 54, 0.1)',
            color: '#f44336'
          }}
          onClose={clearError}
        >
          {error}
        </Alert>
      )}

      <Box component="form" onSubmit={handleSubmit} noValidate>
        <TextField
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoComplete="username"
          autoFocus
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.23)',
              },
              '&:hover fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.5)',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#FC1503',
              },
            },
            '& .MuiInputLabel-root': {
              color: 'rgba(255, 255, 255, 0.7)',
            },
            '& .MuiInputBase-input': {
              color: 'white',
            },
            '& .MuiFormHelperText-root': {
              color: 'rgba(255, 255, 255, 0.7)',
            },
          }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                  sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.23)',
              },
              '&:hover fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.5)',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#FC1503',
              },
            },
            '& .MuiInputLabel-root': {
              color: 'rgba(255, 255, 255, 0.7)',
            },
            '& .MuiInputBase-input': {
              color: 'white',
            },
            '& .MuiFormHelperText-root': {
              color: 'rgba(255, 255, 255, 0.7)',
            },
          }}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ 
            mt: 3, 
            mb: 2, 
            py: 1.2,
            bgcolor: '#FC1503',
            color: 'white',
            '&:hover': {
              bgcolor: '#d01001',
            },
            '&.Mui-disabled': {
              color: 'rgba(255, 255, 255, 0.3)',
              bgcolor: 'rgba(252, 21, 3, 0.5)',
            }
          }}
          disabled={loading || !username || !password}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : 'Sign In'}
        </Button>
      </Box>
    </Paper>
  );
};

export default AdminLogin; 