import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { Link } from 'react-router-dom';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false,
      error: null,
      errorInfo: null
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo
    });
    
    // Log to console in development
    if (process.env.NODE_ENV === 'development') {
      console.error('Error caught by ErrorBoundary:', error);
      console.error('Component stack:', errorInfo.componentStack);
    }
    
    // Try to log to Sentry if available
    try {
      if (window.Sentry) {
        window.Sentry.captureException(error, {
          extra: {
            componentStack: errorInfo.componentStack,
            componentName: this.props.componentName || 'unknown'
          }
        });
      }
    } catch (e) {
      console.warn('Failed to log error to Sentry:', e);
    }
    
    // Try to log to analytics if available
    try {
      if (window.gtag) {
        window.gtag('event', 'error', {
          'event_category': 'Error',
          'event_label': error.toString(),
          'value': this.props.componentName || 'unknown'
        });
      }
    } catch (e) {
      console.warn('Failed to log error to analytics:', e);
    }
  }

  render() {
    if (this.state.hasError) {
      // Fallback UI
      return (
        <Container maxWidth="md">
          <Box 
            sx={{ 
              mt: 8, 
              p: 4, 
              bgcolor: 'rgba(0,0,0,0.7)', 
              borderRadius: 2,
              textAlign: 'center'
            }}
          >
            <Typography variant="h4" color="error" gutterBottom>
              Something went wrong
            </Typography>
            
            <Typography variant="body1" color="white" paragraph>
              We're sorry, but there was an error loading this content. Our team has been notified.
            </Typography>
            
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', gap: 2 }}>
              <Button 
                variant="contained" 
                color="primary"
                onClick={() => window.location.reload()}
              >
                Reload Page
              </Button>
              
              <Button 
                variant="outlined" 
                color="secondary"
                component={Link}
                to="/"
              >
                Go to Home
              </Button>
            </Box>
            
            {process.env.NODE_ENV === 'development' && this.state.error && (
              <Box sx={{ mt: 4, textAlign: 'left', p: 2, bgcolor: 'rgba(0,0,0,0.5)' }}>
                <Typography variant="subtitle2" color="error">
                  Error Details (visible in development only):
                </Typography>
                <Typography variant="body2" component="pre" sx={{ color: '#f44336', fontSize: '0.8rem', overflow: 'auto', maxHeight: '200px' }}>
                  {this.state.error.toString()}
                </Typography>
                <Typography variant="body2" component="pre" sx={{ color: '#f44336', fontSize: '0.8rem', overflow: 'auto', maxHeight: '200px' }}>
                  {this.state.errorInfo && this.state.errorInfo.componentStack}
                </Typography>
              </Box>
            )}
          </Box>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary; 