import { useState, useEffect } from 'react';
import { Box, Grid, ToggleButton, ToggleButtonGroup, Tooltip, useMediaQuery, useTheme, Avatar, Typography, Badge } from '@mui/material';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import VerifiedIcon from '@mui/icons-material/Verified';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { demoThumbnailUrl, demoVideoUrl, demoVideoTitle, demoChannelTitle } from '../utils/constants';
import CommentSection from './CommentSection';
import { formatViews, formatPublishedAt, formatDuration, formatLikes, formatComments } from '../utils/formatters';
import { API_STATUS } from '../utils/fetchFromAPI';
import { useApiStatus, useRenderTime } from '../utils/hooks';
import AffiliateAd from './AffiliateAd';

const VideoThumbnail = ({ layout, video }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  // Debug log the video object
  console.log("VideoThumbnail received video:", video);
  
  const layouts = {
    grid: {
      minHeight: isMobile ? 280 : 340,
      flexDirection: 'column',
      width: '100%'
    },
    compact: {
      minHeight: isMobile ? 220 : 260,
      flexDirection: 'column',
      width: '100%'
    },
    list: {
      height: isMobile ? 200 : 220,
      flexDirection: 'row',
      width: '100%'
    }
  };

  const videoId = video?.id?.videoId || video?.id;
  const snippet = video?.snippet || {};

  return (
    <Box
      component={motion.div}
      layout
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.8 }}
      whileHover={{ scale: 1.02 }}
      transition={{ duration: 0.2 }}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        ...layouts[layout],
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        borderRadius: '12px',
        overflow: 'hidden',
        cursor: 'pointer',
        position: 'relative',
        fontFamily: 'var(--font-primary)',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          '& .video-duration': {
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
          },
          '& .video-stats': {
            opacity: 1,
          }
        },
        ...(layout === 'list' && {
          '&::after': {
            content: '""',
            position: 'absolute',
            right: '16px',
            top: '50%',
            transform: 'translateY(-50%)',
            width: '24px',
            height: '24px',
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ffffff' opacity='0.5'%3E%3Cpath d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z'/%3E%3C/svg%3E")`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            transition: 'transform 0.2s',
            zIndex: 10,
            opacity: 0.5,
          },
          '&:hover::after': {
            opacity: 0.8,
          }
        })
      }}
    >
      <Link 
        to={videoId ? `/video/${videoId}` : demoVideoUrl} 
        style={{ 
          textDecoration: 'none', 
          color: 'inherit', 
          width: '100%',
          display: 'flex',
          flexDirection: layout === 'list' ? 'row' : 'column',
          height: '100%'
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: layout === 'list' ? '40%' : '100%',
            minWidth: layout === 'list' ? '40%' : '100%',
            height: layout === 'list' ? '100%' : 'auto',
            paddingTop: layout === 'list' ? 0 : '56.25%', // 16:9 aspect ratio
            overflow: 'hidden',
            borderRadius: layout === 'list' ? '12px' : '12px 12px 0 0',
          }}
        >
          <Box
            component="img"
            src={snippet?.thumbnails?.high?.url || demoThumbnailUrl}
            alt={snippet?.title}
            sx={{
              position: layout === 'list' ? 'relative' : 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
          <Box
            className="video-duration"
            sx={{
              position: 'absolute',
              bottom: 8,
              right: 8,
              padding: '2px 6px',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              color: 'white',
              borderRadius: '4px',
              fontSize: '0.75rem',
              fontWeight: 'var(--font-weight-medium)',
              letterSpacing: '0.3px',
              transition: 'background-color 0.2s',
              zIndex: 1
            }}
          >
            {formatDuration(video?.contentDetails?.duration) || '0:00'}
          </Box>
          
          <Box
            className="video-stats"
            sx={{
              position: 'absolute',
              bottom: 8,
              left: 8,
              display: 'flex',
              gap: 1,
              opacity: 0,
              transition: 'opacity 0.2s',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              padding: '2px 6px',
              borderRadius: '4px',
              fontSize: '0.75rem',
              fontWeight: 'var(--font-weight-medium)',
              color: 'white',
              zIndex: 1
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <ThumbUpOutlinedIcon sx={{ fontSize: '0.9rem' }} />
              {formatLikes(video?.statistics?.likeCount)}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <ChatOutlinedIcon sx={{ fontSize: '0.9rem' }} />
              {formatComments(video?.statistics?.commentCount)}
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            padding: 1.5,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            minHeight: 0
          }}
        >
          <Box
            sx={{
              fontSize: layout === 'compact' ? '0.9rem' : '1rem',
              fontWeight: 'var(--font-weight-semibold)',
              letterSpacing: '-0.3px',
              lineHeight: '1.3',
              color: 'white',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: layout === 'list' ? 2 : (layout === 'compact' ? 1 : 2),
              WebkitBoxOrient: 'vertical',
              mb: 0.5
            }}
          >
            {snippet?.title || demoVideoTitle}
          </Box>
          
          <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1.5 }}>
            <Link 
              to={`/channel/${snippet?.channelId}`} 
              style={{ 
                color: 'inherit', 
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'flex-start',
                gap: '8px'
              }}
            >
              <Avatar 
                src={video?.channelThumbnail || snippet?.channelThumbnail} 
                alt={snippet?.channelTitle}
                sx={{ 
                  width: layout === 'compact' ? 24 : 28, 
                  height: layout === 'compact' ? 24 : 28,
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  fontSize: '0.8rem'
                }}
              >
                {snippet?.channelTitle?.[0]?.toUpperCase() || 'C'}
              </Avatar>
              <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, minWidth: 0 }}>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 0.5,
                  color: 'white',
                  fontSize: layout === 'compact' ? '0.8rem' : '0.85rem',
                  fontWeight: 'var(--font-weight-medium)',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>
                  {snippet?.channelTitle || demoChannelTitle}
                  {video?.channelVerified && (
                    <VerifiedIcon sx={{ fontSize: '1rem', color: 'gray', flexShrink: 0 }} />
                  )}
                </Box>
                <Box sx={{ 
                  fontSize: '0.75rem',
                  color: 'gray',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>
                  <span>{formatViews(video?.statistics?.viewCount)}</span>
                  •
                  <span>{formatPublishedAt(snippet?.publishedAt)}</span>
                </Box>
              </Box>
            </Link>
          </Box>
          
          {layout === 'list' && snippet?.description && (
            <Box 
              sx={{ 
                fontSize: '0.8rem',
                color: 'rgba(255, 255, 255, 0.7)',
                mt: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {snippet.description}
            </Box>
          )}
        </Box>
      </Link>
    </Box>
  );
};

const VideoGrid = ({ videos, dataSourceStatus = API_STATUS.LOADING }) => {
  const [layout, setLayout] = useState('grid');
  
  // Track render performance
  useRenderTime('VideoGrid');

  // Debug log the videos
  console.log("VideoGrid received videos:", videos);

  const handleLayoutChange = (event, newLayout) => {
    if (newLayout !== null) {
      setLayout(newLayout);
    }
  };

  const getGridProps = () => {
    switch (layout) {
      case 'compact':
        return {
          xs: 12,
          sm: 6,
          md: 4,
          lg: 3,
          spacing: 2
        };
      case 'list':
        return {
          xs: 12,
          sm: 12,
          md: 12,
          lg: 12,
          spacing: 2
        };
      default: // grid
        return {
          xs: 12,
          sm: 6,
          md: 4,
          lg: 3,
          spacing: 3
        };
    }
  };

  const gridProps = getGridProps();

  // Status indicator styles
  const getStatusColor = () => {
    switch(dataSourceStatus) {
      case API_STATUS.ONLINE: return '#4caf50'; // Green
      case API_STATUS.OFFLINE: return '#f44336'; // Red
      default: return '#ffc107'; // Yellow for loading
    }
  };

  const getStatusLabel = () => {
    switch(dataSourceStatus) {
      case API_STATUS.ONLINE: return 'Live data source connected';
      case API_STATUS.OFFLINE: return 'Using fallback data';
      default: return 'Connecting to data source...';
    }
  };

  return (
    <Box sx={{ width: '100%', mb: 8 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          backdropFilter: 'blur(10px)',
          padding: '8px 16px',
          borderRadius: '12px',
          fontFamily: 'var(--font-primary)',
          position: 'sticky',
          top: 0,
          zIndex: 10
        }}
      >
        <Typography 
          variant="subtitle1" 
          sx={{ 
            color: 'white', 
            fontWeight: 'var(--font-weight-medium)',
            display: { xs: 'none', sm: 'block' }
          }}
        >
          {layout === 'list' ? 'List View' : layout === 'compact' ? 'Compact View' : 'Grid View'}
        </Typography>
        
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Tooltip title={getStatusLabel()}>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1 
            }}>
              <Box 
                component={motion.div}
                animate={{ 
                  scale: [1, 1.2, 1],
                  opacity: [0.7, 1, 0.7]
                }}
                transition={{ 
                  duration: 2, 
                  repeat: Number.POSITIVE_INFINITY,
                  ease: "easeInOut" 
                }}
                sx={{ 
                  width: 10, 
                  height: 10, 
                  borderRadius: '50%', 
                  backgroundColor: getStatusColor(),
                  display: 'inline-block'
                }} 
              />
              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                {dataSourceStatus === API_STATUS.ONLINE ? 'Live' : dataSourceStatus === API_STATUS.OFFLINE ? 'Fallback' : 'Loading'}
              </Typography>
            </Box>
          </Tooltip>
          
          <ToggleButtonGroup
            value={layout}
            exclusive
            onChange={handleLayoutChange}
            size="small"
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              padding: '4px',
              borderRadius: '8px',
              '& .MuiToggleButton-root': {
                color: 'gray',
                fontFamily: 'inherit',
                border: 'none',
                borderRadius: '6px',
                margin: '0 2px',
                padding: '6px 12px',
                '&.Mui-selected': {
                  color: 'white',
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.15)'
                  }
                },
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.05)'
                }
              }
            }}
          >
            <ToggleButton value="grid">
              <Tooltip title="Grid View">
                <ViewModuleIcon fontSize="small" />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="compact">
              <Tooltip title="Compact View">
                <ViewComfyIcon fontSize="small" />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="list">
              <Tooltip title="List View">
                <ViewListIcon fontSize="small" />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>

      <AnimatePresence mode="wait">
        <Grid container spacing={gridProps.spacing}>
          {videos?.map((item, index) => (
            <Grid item key={item?.id?.videoId || item?.id || index} xs={gridProps.xs} sm={gridProps.sm} md={gridProps.md} lg={gridProps.lg}>
              <VideoThumbnail layout={layout} video={item} />
            </Grid>
          ))}
        </Grid>
      </AnimatePresence>
    </Box>
  );
};

export default VideoGrid; 