import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, IconButton, Collapse, Divider, Chip } from '@mui/material';
import BugReportIcon from '@mui/icons-material/BugReport';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useApiStatus } from '../utils/hooks';
import { API_STATUS } from '../utils/fetchFromAPI';
import { useAuth } from '../utils/AuthContext';

/**
 * Debug panel component that shows API status and other debugging information
 * Only visible in development mode or when authenticated as admin
 * 
 */
const DebugPanel = () => {
  const [expanded, setExpanded] = useState(false);
  const { status, isUsingRealData, isUsingFallbackData, isLoading } = useApiStatus();
  const [apiCalls, setApiCalls] = useState([]);
  const [memoryUsage, setMemoryUsage] = useState(null);
  const { isAuthenticated } = useAuth();
  
  // Only show in development mode or when authenticated as admin
  if (import.meta.env.MODE !== 'development' && !isAuthenticated) {
    return null;
  }
  
  // Listen for API calls
  useEffect(() => {
    const handleApiCall = (event) => {
      setApiCalls(prev => [
        {
          endpoint: event.detail.endpoint,
          success: event.detail.success,
          time: event.detail.time,
          timestamp: new Date().toISOString()
        },
        ...prev.slice(0, 9) // Keep only the last 10 calls
      ]);
    };
    
    window.addEventListener('api-call', handleApiCall);
    
    // Update memory usage every 5 seconds if available
    const memoryTimer = setInterval(() => {
      if (window.performance?.memory) {
        setMemoryUsage({
          usedJSHeapSize: (window.performance.memory.usedJSHeapSize / (1024 * 1024)).toFixed(2),
          totalJSHeapSize: (window.performance.memory.totalJSHeapSize / (1024 * 1024)).toFixed(2)
        });
      }
    }, 5000);
    
    return () => {
      window.removeEventListener('api-call', handleApiCall);
      clearInterval(memoryTimer);
    };
  }, []);
  
  const getStatusColor = () => {
    switch(status) {
      case API_STATUS.ONLINE: return 'success';
      case API_STATUS.OFFLINE: return 'error';
      default: return 'warning';
    }
  };
  
  const getStatusText = () => {
    switch(status) {
      case API_STATUS.ONLINE: return 'Online';
      case API_STATUS.OFFLINE: return 'Offline';
      default: return 'Loading';
    }
  };
  
  return (
    <Paper
      elevation={3}
      sx={{
        position: 'fixed',
        bottom: 16,
        right: 16,
        zIndex: 9999,
        width: expanded ? 350 : 'auto',
        opacity: 0.9,
        transition: 'width 0.3s ease',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        color: 'white',
        borderRadius: 2
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
        <IconButton 
          size="small" 
          onClick={() => setExpanded(!expanded)}
          sx={{ color: 'white' }}
        >
          <BugReportIcon fontSize="small" />
        </IconButton>
        
        <Typography variant="caption" sx={{ ml: 1, fontWeight: 'medium' }}>
          Debug {isAuthenticated ? '(Admin)' : ''}
        </Typography>
        
        <Chip 
          label={getStatusText()} 
          color={getStatusColor()} 
          size="small" 
          sx={{ ml: 1, height: 20, '& .MuiChip-label': { px: 1, py: 0 } }} 
        />
        
        <IconButton 
          size="small" 
          onClick={() => setExpanded(!expanded)}
          sx={{ ml: 'auto', color: 'white' }}
        >
          {expanded ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
        </IconButton>
      </Box>
      
      <Collapse in={expanded}>
        <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }} />
        
        <Box sx={{ p: 1.5 }}>
          <Typography variant="caption" sx={{ display: 'block', mb: 0.5, color: 'gray' }}>
            API Status
          </Typography>
          
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mb: 1.5 }}>
            <Chip 
              label={`Status: ${getStatusText()}`} 
              color={getStatusColor()} 
              size="small" 
              variant="outlined"
            />
            <Chip 
              label={isUsingRealData() ? 'Using Real Data' : 'Using Mock Data'} 
              color={isUsingRealData() ? 'success' : 'warning'} 
              size="small" 
              variant="outlined"
            />
          </Box>
          
          <Typography variant="caption" sx={{ display: 'block', mb: 0.5, color: 'gray' }}>
            Recent API Calls
          </Typography>
          
          <Box sx={{ maxHeight: 150, overflowY: 'auto', mb: 1.5 }}>
            {apiCalls.length === 0 ? (
              <Typography variant="caption" sx={{ color: 'text.secondary', fontStyle: 'italic' }}>
                No API calls recorded yet
              </Typography>
            ) : (
              apiCalls.map((call) => (
                <Box key={`${call.timestamp}-${call.endpoint}`} sx={{ mb: 0.5, fontSize: '0.7rem' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                    <Box 
                      sx={{ 
                        width: 8, 
                        height: 8, 
                        borderRadius: '50%', 
                        backgroundColor: call.success ? 'success.main' : 'error.main' 
                      }} 
                    />
                    <Typography variant="caption" sx={{ fontFamily: 'monospace', fontSize: '0.7rem' }}>
                      {call.endpoint.substring(0, 30)}{call.endpoint.length > 30 ? '...' : ''}
                    </Typography>
                  </Box>
                  <Typography 
                    variant="caption" 
                    sx={{ 
                      color: 'text.secondary', 
                      fontSize: '0.65rem',
                      ml: 1.5
                    }}
                  >
                    {call.time.toFixed(0)}ms • {new Date(call.timestamp).toLocaleTimeString()}
                  </Typography>
                </Box>
              ))
            )}
          </Box>
          
          {memoryUsage && (
            <>
              <Typography variant="caption" sx={{ display: 'block', mb: 0.5, color: 'gray' }}>
                Memory Usage
              </Typography>
              
              <Box sx={{ fontSize: '0.7rem', mb: 1 }}>
                <Typography variant="caption" sx={{ display: 'block', fontFamily: 'monospace', fontSize: '0.7rem' }}>
                  Used: {memoryUsage.usedJSHeapSize} MB / {memoryUsage.totalJSHeapSize} MB
                </Typography>
              </Box>
            </>
          )}
          
          <Typography variant="caption" sx={{ display: 'block', color: 'text.secondary', fontSize: '0.65rem' }}>
            Environment: {import.meta.env.MODE} • Build: {import.meta.env.VITE_APP_VERSION || 'dev'}
          </Typography>
        </Box>
      </Collapse>
    </Paper>
  );
};

export default DebugPanel; 